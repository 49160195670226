exports.components = {
  "component---src-layouts-partner-index-jsx": () => import("./../../../src/layouts/Partner/index.jsx" /* webpackChunkName: "component---src-layouts-partner-index-jsx" */),
  "component---src-layouts-sanity-blog-index-jsx": () => import("./../../../src/layouts/SanityBlog/index.jsx" /* webpackChunkName: "component---src-layouts-sanity-blog-index-jsx" */),
  "component---src-mdx-pages-affiliate-layout-jsx-content-file-path-src-mdx-pages-affiliate-terms-index-mdx": () => import("./../../../src/mdxPages/affiliate/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/affiliate/terms/index.mdx" /* webpackChunkName: "component---src-mdx-pages-affiliate-layout-jsx-content-file-path-src-mdx-pages-affiliate-terms-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2008-10-06-example-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2008-10-06-example/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2008-10-06-example-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-03-16-farewell-beta-hello-the-new-javelin-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2009-03-16-farewell_beta_hello_the_new_javelin/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-03-16-farewell-beta-hello-the-new-javelin-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-04-08-the-value-of-integrated-solutions-for-small-business-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2009-04-08-the_value_of_integrated_solutions_for_small_business/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-04-08-the-value-of-integrated-solutions-for-small-business-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-04-15-announcing-xero-and-kashflow-integration-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2009-04-15-announcing_xero_and_kashflow_integration/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-04-15-announcing-xero-and-kashflow-integration-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-05-07-tweaks-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2009-05-07-tweaks/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-05-07-tweaks-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-07-17-freeagent-integration-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2009-07-17-freeagent_integration/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-07-17-freeagent-integration-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-07-30-email-marketing-with-mailchimp-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2009-07-30-email_marketing_with_mailchimp/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-07-30-email-marketing-with-mailchimp-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-09-23-api-for-developers-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2009-09-23-api_for_developers/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-09-23-api-for-developers-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-10-01-freshbooks-crm-integration-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2009-10-01-freshbooks-crm-integration/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-10-01-freshbooks-crm-integration-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-12-03-internet-explorer-is-officially-square-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2009-12-03-internet_explorer_is_officially_square/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-12-03-internet-explorer-is-officially-square-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-01-29-javelin-is-being-renamed-to-capsule-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2010-01-29-javelin_is_being_renamed_to_capsule/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-01-29-javelin-is-being-renamed-to-capsule-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-01-31-javelin-is-now-capsule-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2010-01-31-javelin_is_now_capsule/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-01-31-javelin-is-now-capsule-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-02-08-new-in-capsule-calendar-view-and-task-categories-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2010-02-08-new-in-capsule-calendar-view-and-task-categories/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-02-08-new-in-capsule-calendar-view-and-task-categories-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-02-19-quick-contact-preview-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2010-02-19-quick_contact_preview/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-02-19-quick-contact-preview-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-03-10-capsule-integrates-with-google-apps-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2010-03-10-capsule_integrates_with_google_apps/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-03-10-capsule-integrates-with-google-apps-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-04-06-php-wrapper-for-api-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2010-04-06-php_wrapper_for_api/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-04-06-php-wrapper-for-api-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-06-01-new-in-capsule-pictures-from-social-networks-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2010-06-01-new-in-capsule-pictures-from-social-networks/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-06-01-new-in-capsule-pictures-from-social-networks-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-09-15-one-click-export-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2010-09-15-one-click-export/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-09-15-one-click-export-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-02-04-capsule-contextual-gadget-for-gmail-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2011-02-04-capsule-contextual-gadget-for-gmail/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-02-04-capsule-contextual-gadget-for-gmail-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-03-01-easy-forms-for-your-website-with-wufoo-integration-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2011-03-01-easy-forms-for-your-website-with-wufoo-integration/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-03-01-easy-forms-for-your-website-with-wufoo-integration-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-04-20-launch-of-capsule-mobile-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2011-04-20-launch-of-capsule-mobile/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-04-20-launch-of-capsule-mobile-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-06-20-speedy-date-entry-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2011-06-20-speedy-date-entry/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-06-20-speedy-date-entry-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-07-03-navigation-refresh-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2011-07-03-navigation-refresh/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-07-03-navigation-refresh-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-07-20-quote-roller-integrates-with-capsule-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2011-07-20-quote-roller-integrates-with-capsule/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-07-20-quote-roller-integrates-with-capsule-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-09-27-new-capsule-support-portal-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2011-09-27-new-capsule-support-portal/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-09-27-new-capsule-support-portal-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-10-27-capsulecrm-com-refresh-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2011-10-27-capsulecrm-com-refresh/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-10-27-capsulecrm-com-refresh-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2012-03-06-new-in-capsule-find-social-profiles-for-your-contacts-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2012-03-06-new-in-capsule-find-social-profiles-for-your-contacts/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2012-03-06-new-in-capsule-find-social-profiles-for-your-contacts-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2012-09-06-refined-pop-over-contact-cards-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2012-09-06-refined-pop-over-contact-cards/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2012-09-06-refined-pop-over-contact-cards-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2012-12-17-now-accepting-american-express-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2012-12-17-now-accepting-american-express/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2012-12-17-now-accepting-american-express-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2013-05-03-updated-twitter-integration-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2013-05-03-updated-twitter-integration/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2013-05-03-updated-twitter-integration-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2013-07-10-capsule-for-mobile-now-available-in-app-stores-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2013-07-10-capsule-for-mobile-now-available-in-app-stores/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2013-07-10-capsule-for-mobile-now-available-in-app-stores-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2013-10-01-improved-task-reminder-emails-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2013-10-01-improved-task-reminder-emails/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2013-10-01-improved-task-reminder-emails-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2013-11-28-google-apps-marketplace-improvements-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2013-11-28-google-apps-marketplace-improvements/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2013-11-28-google-apps-marketplace-improvements-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2013-12-18-updates-to-the-capsule-app-for-ios-and-android-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2013-12-18-updates-to-the-capsule-app-for-ios-and-android/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2013-12-18-updates-to-the-capsule-app-for-ios-and-android-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-01-08-support-portal-redesign-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2014-01-08-support-portal-redesign/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-01-08-support-portal-redesign-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-01-24-tags-now-available-in-capsule-app-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2014-01-24-tags-now-available-in-capsule-app/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-01-24-tags-now-available-in-capsule-app-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-03-13-linkedin-integration-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2014-03-13-linkedin-integration/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-03-13-linkedin-integration-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-04-18-new-gmail-gadget-coming-for-google-apps-users-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2014-04-18-new-gmail-gadget-coming-for-google-apps-users/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-04-18-new-gmail-gadget-coming-for-google-apps-users-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-07-24-restore-from-trash-for-added-peace-of-mind-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2014-07-24-restore-from-trash-for-added-peace-of-mind/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-07-24-restore-from-trash-for-added-peace-of-mind-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-08-13-2-way-sync-to-google-contacts-via-pie-sync-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2014-08-13-2way-sync-to-google-contacts-via-pie-sync/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-08-13-2-way-sync-to-google-contacts-via-pie-sync-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-09-02-more-powerful-searching-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2014-09-02-more-powerful-searching/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-09-02-more-powerful-searching-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-09-18-hack-manchester-challenge-2014-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2014-09-18-hack-manchester-challenge-2014/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-09-18-hack-manchester-challenge-2014-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-09-23-a-new-look-capsule-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2014-09-23-a-new-look-capsule/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-09-23-a-new-look-capsule-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-11-11-new-duplicate-checks-and-task-category-colors-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2014-11-11-new-duplicate-checks-and-task-category-colors/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-11-11-new-duplicate-checks-and-task-category-colors-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-12-10-repeating-tasks-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2014-12-10-repeating-tasks/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-12-10-repeating-tasks-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2015-01-14-updates-to-our-support-portal-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2015-01-14-updates-to-our-support-portal/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2015-01-14-updates-to-our-support-portal-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2015-04-08-better-duplicate-checking-and-merging-on-import-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2015-04-08-better-duplicate-checking-and-merging-on-import/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2015-04-08-better-duplicate-checking-and-merging-on-import-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2015-05-20-improved-tracks-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2015-05-20-improved-tracks/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2015-05-20-improved-tracks-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2015-09-11-recent-updates-sep-2015-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2015-09-11-recent-updates-sep-2015/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2015-09-11-recent-updates-sep-2015-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2016-02-17-improved-social-search-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2016-02-17-improved-social-search/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2016-02-17-improved-social-search-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2016-03-15-introducing-two-factor-authentication-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2016-03-15-introducing-two-factor-authentication/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2016-03-15-introducing-two-factor-authentication-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2016-05-17-recent-updates-may-2016-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2016-05-17-recent-updates-may-2016/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2016-05-17-recent-updates-may-2016-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2016-08-03-restore-account-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2016-08-03-restore-account/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2016-08-03-restore-account-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2016-08-24-pandadoc-integration-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2016-08-24-pandadoc-integration/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2016-08-24-pandadoc-integration-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2016-09-12-covering-more-support-hours-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2016-09-12-covering-more-support-hours/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2016-09-12-covering-more-support-hours-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2016-11-15-recent-updates-november-2016-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2016-11-15-recent-updates-november-2016/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2016-11-15-recent-updates-november-2016-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-01-20-api-v-2-release-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2017-01-20-api-v2-release/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-01-20-api-v-2-release-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-02-28-new-logo-and-brand-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2017-02-28-new-logo-and-brand/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-02-28-new-logo-and-brand-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-08-14-custom-fields-and-tags-in-the-mobile-app-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2017-08-14-custom-fields-and-tags-in-the-mobile-app/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-08-14-custom-fields-and-tags-in-the-mobile-app-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-09-14-tab-navigation-mobile-app-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2017-09-14-tab-navigation-mobile-app/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-09-14-tab-navigation-mobile-app-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-09-25-sage-one-integration-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2017-09-25-sage-one-integration/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-09-25-sage-one-integration-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-10-06-mobile-change-contact-pictures-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2017-10-06-mobile-change-contact-pictures/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-10-06-mobile-change-contact-pictures-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-10-12-tracks-assign-feature-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2017-10-12-tracks-assign-feature/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-10-12-tracks-assign-feature-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-11-13-mobile-attach-files-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2017-11-13-mobile-attach-files/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-11-13-mobile-attach-files-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-01-15-circleloop-integration-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-01-15-circleloop-integration/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-01-15-circleloop-integration-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-01-19-january-news-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-01-19-january-news/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-01-19-january-news-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-02-15-mobile-tasks-calendar-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-02-15-mobile-tasks-calendar/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-02-15-mobile-tasks-calendar-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-03-27-capsule-and-gdpr-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-03-27-capsule-and-gdpr/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-03-27-capsule-and-gdpr-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-04-16-visualize-sales-pipeline-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-04-16-visualize-sales-pipeline/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-04-16-visualize-sales-pipeline-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-01-new-contacts-list-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-05-01-new-contacts-list/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-01-new-contacts-list-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-01-new-contacts-list-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-05-01-new-contacts-list/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-01-new-contacts-list-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-01-new-contacts-list-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-05-01-new-contacts-list/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-01-new-contacts-list-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-01-new-contacts-list-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-05-01-new-contacts-list/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-01-new-contacts-list-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-11-zendesk-app-update-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-05-11-zendesk-app-update/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-11-zendesk-app-update-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-25-capsule-and-gdpr-part-2-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-05-25-capsule-and-gdpr-part-2/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-25-capsule-and-gdpr-part-2-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-08-01-recent-updates-august-2018-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-08-01-recent-updates-august-2018/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-08-01-recent-updates-august-2018-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-08-07-gmail-add-on-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-08-07-gmail-add-on/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-08-07-gmail-add-on-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-10-22-new-ways-to-interact-with-cases-and-oportunities-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-10-22-new-ways-to-interact-with-cases-and-oportunities/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-10-22-new-ways-to-interact-with-cases-and-oportunities-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-11-01-gmail-add-on-improvements-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-11-01-gmail-add-on-improvements/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-11-01-gmail-add-on-improvements-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-11-13-teams-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-11-13-teams/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-11-13-teams-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-12-11-activity-reporting-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-12-11-activity-reporting/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-12-11-activity-reporting-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-12-13-gmail-add-on-updated-assignment-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-12-13-gmail-add-on-updated-assignment/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-12-13-gmail-add-on-updated-assignment-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-01-7-zapier-integration-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-01-7-zapier-integration/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-01-7-zapier-integration-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-03-06-create-your-own-reports-with-looker-studio-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-03-06-create-your-own-reports-with-looker-studio/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-03-06-create-your-own-reports-with-looker-studio-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-03-12-stale-opportunities-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-03-12-stale-opportunities/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-03-12-stale-opportunities-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-03-13-lost-reasons-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-03-13-lost-reasons/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-03-13-lost-reasons-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-04-09-remote-working-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-04-09-remote-working/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-04-09-remote-working-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-04-11-xero-integration-with-capsule-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-04-11-xero-integration-with-capsule/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-04-11-xero-integration-with-capsule-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-04-23-how-to-implement-your-crm-successfully-part-2-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-04-23-how-to-implement-your-crm-successfully-part-2/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-04-23-how-to-implement-your-crm-successfully-part-2-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-04-30-how-to-implement-your-crm-successfully-part-3-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-04-30-how-to-implement-your-crm-successfully-part-3/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-04-30-how-to-implement-your-crm-successfully-part-3-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-05-03-boost-sales-reports-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-05-03-boost-sales-reports/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-05-03-boost-sales-reports-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-05-09-projects-blog-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-05-09-projects-blog/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-05-09-projects-blog-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-05-16-be-more-productive-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-05-16-be-more-productive/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-05-16-be-more-productive-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-05-29-do-less-with-more-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-05-29-do-less-with-more/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-05-29-do-less-with-more-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-06-05-ten-year-anniversary-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-06-05-ten-year-anniversary/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-06-05-ten-year-anniversary-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-06-19-gmail-add-on-capsule-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-06-19-gmail-add-on-capsule/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-06-19-gmail-add-on-capsule-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-03-quickbooks-sneak-peek-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-07-03-quickbooks-sneak-peek/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-03-quickbooks-sneak-peek-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-08-caller-id-support-for-ios-mobile-app-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-07-08-caller-id-support-for-ios-mobile-app/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-08-caller-id-support-for-ios-mobile-app-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-26-moving-crms-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-07-26-moving-crms/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-26-moving-crms-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-26-moving-crms-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-07-26-moving-crms/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-26-moving-crms-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-26-moving-crms-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-07-26-moving-crms/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-26-moving-crms-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-26-moving-crms-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-07-26-moving-crms/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-26-moving-crms-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-08-15-10-things-to-do-in-manchester-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-08-15-10-things-to-do-in-manchester/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-08-15-10-things-to-do-in-manchester-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-08-21-automatically-log-calls-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-08-21-automatically-log-calls/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-08-21-automatically-log-calls-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-09-06-duncan-10-years-blog-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-09-06-duncan-10-years-blog/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-09-06-duncan-10-years-blog-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-10-16-festival-of-marketing-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-10-16-festival-of-marketing/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-10-16-festival-of-marketing-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-11-13-forms-and-navigation-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-11-13-forms-and-navigation/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-11-13-forms-and-navigation-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-11-28-quickbooks-online-integration-is-here-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-11-28-quickbooks-online-integration-is-here/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-11-28-quickbooks-online-integration-is-here-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-12-03-capsule-lists-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-12-03-capsule-lists/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-12-03-capsule-lists-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-12-06-remote-workers-christmas-games-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-12-06-remote-workers-christmas-games/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-12-06-remote-workers-christmas-games-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-12-23-capsule-year-in-numbers-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-12-23-capsule-year-in-numbers/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-12-23-capsule-year-in-numbers-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-01-14-announcing-our-new-sidebar-xero-quickbooks-tasks-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-01-14-announcing-our-new-sidebar-xero-quickbooks-tasks/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-01-14-announcing-our-new-sidebar-xero-quickbooks-tasks-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-01-15-how-to-be-more-organized-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-01-15-how-to-be-more-organized/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-01-15-how-to-be-more-organized-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-01-29-win-deals-build-relationships-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-01-29-win-deals-build-relationships/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-01-29-win-deals-build-relationships-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-02-05-how-to-get-teams-using-crm-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-02-05-how-to-get-teams-using-crm/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-02-05-how-to-get-teams-using-crm-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-03-11-digital-amnesia-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-03-11-digital-amnesia/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-03-11-digital-amnesia-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-03-16-remote-working-challenges-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-03-16-remote-working-challenges/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-03-16-remote-working-challenges-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-03-19-social-distancing-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-03-19-social-distancing/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-03-19-social-distancing-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-03-23-organize-crm-data-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-03-23-organize-crm-data/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-03-23-organize-crm-data-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-04-02-woman-in-tech-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-04-02-woman-in-tech/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-04-02-woman-in-tech-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-04-23-key-tools-to-help-run-your-business-remotely-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-04-23-key-tools-to-help-run-your-business-remotely/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-04-23-key-tools-to-help-run-your-business-remotely-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-05-21-having-a-professional-video-call-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-05-21-having-a-professional-video-call/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-05-21-having-a-professional-video-call-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-06-22-june-product-updates-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-06-22-june-product-updates/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-06-22-june-product-updates-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-07-17-investing-in-capsule-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-07-17-investing-in-capsule/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-07-17-investing-in-capsule-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-08-17-building-successful-sales-team-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-08-17-building-successful-sales-team/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-08-17-building-successful-sales-team-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-09-29-report-breakdowns-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-09-29-report-breakdowns/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-09-29-report-breakdowns-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-01-18-capsule-2020-what-we-shipped-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2021-01-18-capsule-2020-what-we-shipped/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-01-18-capsule-2020-what-we-shipped-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-02-15-crm-important-fields-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2021-02-15-crm-important-fields/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-02-15-crm-important-fields-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-05-05-capsule-microsoft-365-integration-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2021-05-05-capsule-microsoft365-integration/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-05-05-capsule-microsoft-365-integration-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-06-10-not-so-secret-ingredient-simplicity-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2021-06-10-not-so-secret-ingredient-simplicity/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-06-10-not-so-secret-ingredient-simplicity-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-07-27-companies-move-cloud-benefits-large-businesses-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2021-07-27-companies-move-cloud-benefits-large-businesses/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-07-27-companies-move-cloud-benefits-large-businesses-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-07-27-companies-move-cloud-benefits-large-businesses-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2021-07-27-companies-move-cloud-benefits-large-businesses/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-07-27-companies-move-cloud-benefits-large-businesses-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-07-27-companies-move-cloud-benefits-large-businesses-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2021-07-27-companies-move-cloud-benefits-large-businesses/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-07-27-companies-move-cloud-benefits-large-businesses-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-07-27-companies-move-cloud-benefits-large-businesses-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2021-07-27-companies-move-cloud-benefits-large-businesses/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-07-27-companies-move-cloud-benefits-large-businesses-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-08-24-multiple-sales-pipelines-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2021-08-24-multiple-sales-pipelines/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-08-24-multiple-sales-pipelines-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-09-13-new-capsule-brand-identity-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2021-09-13-new-capsule-brand-identity/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-09-13-new-capsule-brand-identity-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-11-03-new-email-templates-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2021-11-03-new-email-templates/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-11-03-new-email-templates-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-11-10-what-crm-customers-really-think-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2021-11-10-what-crm-customers-really-think/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-11-10-what-crm-customers-really-think-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-01-20-help-to-grow-digital-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-01-20-help-to-grow-digital/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-01-20-help-to-grow-digital-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-01-25-capsule-outlook-addin-latest-enhancements-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-01-25-capsule-outlook-addin-latest-enhancements/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-01-25-capsule-outlook-addin-latest-enhancements-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-01-25-capsule-outlook-addin-latest-enhancements-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-01-25-capsule-outlook-addin-latest-enhancements/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-01-25-capsule-outlook-addin-latest-enhancements-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-01-25-capsule-outlook-addin-latest-enhancements-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-01-25-capsule-outlook-addin-latest-enhancements/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-01-25-capsule-outlook-addin-latest-enhancements-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-01-25-capsule-outlook-addin-latest-enhancements-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-01-25-capsule-outlook-addin-latest-enhancements/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-01-25-capsule-outlook-addin-latest-enhancements-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-03-02-sales-email-templates-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-03-02-sales-email-templates/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-03-02-sales-email-templates-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-03-04-capsule-capterra-crm-software-shortlist-report-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-03-04-capsule-capterra-crm-software-shortlist-report/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-03-04-capsule-capterra-crm-software-shortlist-report-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-03-08-whats-new-capsule-updates-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-03-08-whats-new-capsule-updates/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-03-08-whats-new-capsule-updates-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-03-16-3-great-features-to-keep-your-capsule-account-tidy-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-03-16-3-great-features-to-keep-your-capsule-account-tidy/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-03-16-3-great-features-to-keep-your-capsule-account-tidy-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-05-25-recent-integrations-leadinfo-paycove-officernd-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-05-25-recent-integrations-leadinfo-paycove-officernd/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-05-25-recent-integrations-leadinfo-paycove-officernd-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-06-22-finding-balance-in-the-modern-day-sales-challenge-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-06-22-finding-balance-in-the-modern-day-sales-challenge/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-06-22-finding-balance-in-the-modern-day-sales-challenge-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-06-30-projects-redesigned-project-management-tool-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-06-30-projects-redesigned-project-management-tool/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-06-30-projects-redesigned-project-management-tool-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-07-26-affiliate-success-stories-interview-julia-blake-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-07-26-affiliate-success-stories-interview-julia-blake/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-07-26-affiliate-success-stories-interview-julia-blake-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-08-26-how-transpond-can-improve-your-business-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-08-26-how-transpond-can-improve-your-business/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-08-26-how-transpond-can-improve-your-business-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-08-31-teams-plan-new-features-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-08-31-teams-plan-new-features/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-08-31-teams-plan-new-features-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-10-03-switch-to-cloud-based-phone-system-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-10-03-switch-to-cloud-based-phone-system/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-10-03-switch-to-cloud-based-phone-system-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-12-05-add-leads-facebook-capsule-with-zapier-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-12-05-add-leads-facebook-capsule-with-zapier/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-12-05-add-leads-facebook-capsule-with-zapier-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-17-what-we-brought-you-in-2022-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-01-17-what-we-brought-you-in-2022/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-17-what-we-brought-you-in-2022-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-18-new-ceo-announcement-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-01-18-new-ceo-announcement/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-18-new-ceo-announcement-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-23-quickbooks-crm-integration-reviews-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-01-23-quickbooks-crm-integration-reviews/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-23-quickbooks-crm-integration-reviews-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-23-quickbooks-crm-integration-reviews-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-01-23-quickbooks-crm-integration-reviews/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-23-quickbooks-crm-integration-reviews-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-23-quickbooks-crm-integration-reviews-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-01-23-quickbooks-crm-integration-reviews/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-23-quickbooks-crm-integration-reviews-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-23-quickbooks-crm-integration-reviews-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-01-23-quickbooks-crm-integration-reviews/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-23-quickbooks-crm-integration-reviews-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-02-22-workflow-automation-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-02-22-workflow-automation/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-02-22-workflow-automation-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-03-22-unlock-productivity-new-ai-content-assistant-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-03-22-unlock-productivity-new-ai-content-assistant/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-03-22-unlock-productivity-new-ai-content-assistant-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-03-22-unlock-productivity-new-ai-content-assistant-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-03-22-unlock-productivity-new-ai-content-assistant/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-03-22-unlock-productivity-new-ai-content-assistant-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-03-22-unlock-productivity-new-ai-content-assistant-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-03-22-unlock-productivity-new-ai-content-assistant/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-03-22-unlock-productivity-new-ai-content-assistant-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-03-22-unlock-productivity-new-ai-content-assistant-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-03-22-unlock-productivity-new-ai-content-assistant/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-03-22-unlock-productivity-new-ai-content-assistant-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-05-16-best-xero-crm-integrations-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-05-16-best-xero-crm-integrations/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-05-16-best-xero-crm-integrations-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-05-16-best-xero-crm-integrations-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-05-16-best-xero-crm-integrations/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-05-16-best-xero-crm-integrations-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-05-16-best-xero-crm-integrations-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-05-16-best-xero-crm-integrations/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-05-16-best-xero-crm-integrations-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-05-16-best-xero-crm-integrations-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-05-16-best-xero-crm-integrations/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-05-16-best-xero-crm-integrations-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-06-20-soc-2-accreditation-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-06-20-soc2-accreditation/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-06-20-soc-2-accreditation-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-09-04-sales-marketing-alignment-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-09-04-sales-marketing-alignment/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-09-04-sales-marketing-alignment-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-09-11-monday-blues-to-monday-wins-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-09-11-monday-blues-to-monday-wins/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-09-11-monday-blues-to-monday-wins-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-09-12-six-new-capsule-integrations-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-09-12-six-new-capsule-integrations/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-09-12-six-new-capsule-integrations-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-09-21-projects-12-ingenious-use-cases-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-09-21-projects-12-ingenious-use-cases/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-09-21-projects-12-ingenious-use-cases-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-10-05-how-zapier-enhances-crm-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-10-05-how-zapier-enhances-crm/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-10-05-how-zapier-enhances-crm-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-10-12-exploring-custom-fields-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-10-12-exploring-custom-fields/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-10-12-exploring-custom-fields-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-10-17-best-sales-memes-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-10-17-best-sales-memes/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-10-17-best-sales-memes-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-10-18-can-you-close-sales-with-close-crm-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-10-18-can-you-close-sales-with-close-crm/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-10-18-can-you-close-sales-with-close-crm-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-10-19-how-to-handle-objections-in-sales-calls-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-10-19-how-to-handle-objections-in-sales-calls/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-10-19-how-to-handle-objections-in-sales-calls-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-10-26-9-tips-optimizing-contact-management-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-10-26-9-tips-optimizing-contact-management/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-10-26-9-tips-optimizing-contact-management-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-11-02-build-first-sales-pipeline-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-11-02-build-first-sales-pipeline/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-11-02-build-first-sales-pipeline-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-11-09-how-to-use-workflow-automation-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-11-09-how-to-use-workflow-automation/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-11-09-how-to-use-workflow-automation-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-11-23-tracks-blueprint-success-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-11-23-tracks-blueprint-success/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-11-23-tracks-blueprint-success-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-11-24-marketing-memes-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-11-24-marketing-memes/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-11-24-marketing-memes-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-12-04-references-template-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-12-04-references-template/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-12-04-references-template-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-12-07-marketing-potential-capsule-transpond-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-12-07-marketing-potential-capsule-transpond/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-12-07-marketing-potential-capsule-transpond-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-12-14-customized-capsule-dashboards-plecto-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-12-14-customized-capsule-dashboards-plecto/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-12-14-customized-capsule-dashboards-plecto-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-12-14-ten-reports-sales-team-performance-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-12-14-ten-reports-sales-team-performance/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-12-14-ten-reports-sales-team-performance-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-03-capsule-2023-roundup-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-03-capsule-2023-roundup/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-03-capsule-2023-roundup-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-03-capsule-2023-roundup-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-03-capsule-2023-roundup/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-03-capsule-2023-roundup-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-03-capsule-2023-roundup-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-03-capsule-2023-roundup/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-03-capsule-2023-roundup-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-03-capsule-2023-roundup-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-03-capsule-2023-roundup/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-03-capsule-2023-roundup-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-09-marketing-plan-templates-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-09-marketing-plan-templates/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-09-marketing-plan-templates-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-09-marketing-plan-templates-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-09-marketing-plan-templates/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-09-marketing-plan-templates-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-09-marketing-plan-templates-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-09-marketing-plan-templates/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-09-marketing-plan-templates-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-09-marketing-plan-templates-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-09-marketing-plan-templates/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-09-marketing-plan-templates-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-10-integrations-business-owners-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-10-integrations-business-owners/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-10-integrations-business-owners-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-10-integrations-salespeople-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-10-integrations-salespeople/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-10-integrations-salespeople-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-17-follow-up-email-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-17-follow-up-email/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-17-follow-up-email-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-19-sales-strategies-templates-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-19-sales-strategies-templates/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-19-sales-strategies-templates-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-24-sales-call-recordings-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-24-sales-call-recordings/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-24-sales-call-recordings-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-24-sales-call-recordings-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-24-sales-call-recordings/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-24-sales-call-recordings-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-24-sales-call-recordings-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-24-sales-call-recordings/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-24-sales-call-recordings-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-24-sales-call-recordings-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-24-sales-call-recordings/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-24-sales-call-recordings-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-25-integrations-marketing-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-25-integrations-marketing/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-25-integrations-marketing-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-02-saved-lists-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-02-02-saved-lists/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-02-saved-lists-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-12-reasons-connect-mailbox-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-02-12-reasons-connect-mailbox/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-12-reasons-connect-mailbox-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-23-best-telephone-voip-crm-integrations-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-02-23-best-telephone-voip-crm-integrations/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-23-best-telephone-voip-crm-integrations-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-23-best-telephone-voip-crm-integrations-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-02-23-best-telephone-voip-crm-integrations/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-23-best-telephone-voip-crm-integrations-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-27-email-verification-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-02-27-email-verification/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-27-email-verification-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-27-email-verification-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-02-27-email-verification/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-27-email-verification-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-27-email-verification-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-02-27-email-verification/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-27-email-verification-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-27-email-verification-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-02-27-email-verification/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-27-email-verification-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-06-french-and-portuguese-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-03-06-french-and-portuguese/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-06-french-and-portuguese-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-15-endless-integration-opportunities-zapier-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-03-15-endless-integration-opportunities-zapier/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-15-endless-integration-opportunities-zapier-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-15-endless-integration-opportunities-zapier-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-03-15-endless-integration-opportunities-zapier/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-15-endless-integration-opportunities-zapier-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-22-professional-services-project-management-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-03-22-professional-services-project-management/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-22-professional-services-project-management-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-22-professional-services-project-management-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-03-22-professional-services-project-management/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-22-professional-services-project-management-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-28-sales-management-collaborative-selling-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-03-28-sales-management-collaborative-selling/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-28-sales-management-collaborative-selling-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-28-seo-lead-generation-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-03-28-seo-lead-generation/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-28-seo-lead-generation-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-28-seo-lead-generation-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-03-28-seo-lead-generation/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-28-seo-lead-generation-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-04-10-managing-renewals-use-projects-effectively-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-04-10-managing-renewals-use-projects-effectively/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-04-10-managing-renewals-use-projects-effectively-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-04-19-what-is-a-sdr-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-04-19-what-is-a-sdr/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-04-19-what-is-a-sdr-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-04-23-software-buying-guide-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-04-23-software-buying-guide/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-04-23-software-buying-guide-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-04-24-how-capsule-can-help-build-a-winning-global-sales-strategy-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-04-24-how-capsule-can-help-build-a-winning-global-sales-strategy/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-04-24-how-capsule-can-help-build-a-winning-global-sales-strategy-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-06-14-the-best-way-to-import-contacts-and-opportunities-into-capsule-from-a-spreadsheet-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-06-14-the-best-way-to-import-contacts-and-opportunities-into-capsule-from-a-spreadsheet/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-06-14-the-best-way-to-import-contacts-and-opportunities-into-capsule-from-a-spreadsheet-index-mdx" */),
  "component---src-mdx-pages-crm-glossary-layout-jsx-content-file-path-src-mdx-pages-crm-glossary-index-en-mdx": () => import("./../../../src/mdxPages/crm-glossary/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/crm-glossary/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-crm-glossary-layout-jsx-content-file-path-src-mdx-pages-crm-glossary-index-en-mdx" */),
  "component---src-mdx-pages-crm-glossary-layout-jsx-content-file-path-src-mdx-pages-crm-glossary-index-es-mdx": () => import("./../../../src/mdxPages/crm-glossary/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/crm-glossary/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-crm-glossary-layout-jsx-content-file-path-src-mdx-pages-crm-glossary-index-es-mdx" */),
  "component---src-mdx-pages-crm-glossary-layout-jsx-content-file-path-src-mdx-pages-crm-glossary-index-fr-mdx": () => import("./../../../src/mdxPages/crm-glossary/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/crm-glossary/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-crm-glossary-layout-jsx-content-file-path-src-mdx-pages-crm-glossary-index-fr-mdx" */),
  "component---src-mdx-pages-crm-glossary-layout-jsx-content-file-path-src-mdx-pages-crm-glossary-index-pt-mdx": () => import("./../../../src/mdxPages/crm-glossary/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/crm-glossary/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-crm-glossary-layout-jsx-content-file-path-src-mdx-pages-crm-glossary-index-pt-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-360-wealth-management-index-en-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/360-wealth-management/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-360-wealth-management-index-en-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-box-factura-index-en-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/box-factura/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-box-factura-index-en-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-box-factura-index-es-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/box-factura/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-box-factura-index-es-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-box-factura-index-fr-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/box-factura/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-box-factura-index-fr-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-box-factura-index-pt-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/box-factura/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-box-factura-index-pt-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-caviar-and-chips-index-en-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/caviar-and-chips/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-caviar-and-chips-index-en-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-caviar-and-chips-index-es-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/caviar-and-chips/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-caviar-and-chips-index-es-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-caviar-and-chips-index-fr-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/caviar-and-chips/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-caviar-and-chips-index-fr-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-caviar-and-chips-index-pt-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/caviar-and-chips/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-caviar-and-chips-index-pt-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-evoluted-index-en-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/evoluted/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-evoluted-index-en-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-evoluted-index-es-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/evoluted/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-evoluted-index-es-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-evoluted-index-fr-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/evoluted/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-evoluted-index-fr-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-evoluted-index-pt-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/evoluted/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-evoluted-index-pt-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-f-8-creates-index-en-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/f8-creates/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-f-8-creates-index-en-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-f-8-creates-index-es-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/f8-creates/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-f-8-creates-index-es-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-f-8-creates-index-fr-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/f8-creates/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-f-8-creates-index-fr-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-f-8-creates-index-pt-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/f8-creates/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-f-8-creates-index-pt-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-grofar-index-en-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/grofar/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-grofar-index-en-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-grofar-index-es-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/grofar/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-grofar-index-es-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-grofar-index-fr-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/grofar/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-grofar-index-fr-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-grofar-index-pt-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/grofar/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-grofar-index-pt-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-haagsch-recherchebureau-index-en-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/haagsch-recherchebureau/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-haagsch-recherchebureau-index-en-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-haagsch-recherchebureau-index-es-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/haagsch-recherchebureau/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-haagsch-recherchebureau-index-es-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-haagsch-recherchebureau-index-fr-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/haagsch-recherchebureau/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-haagsch-recherchebureau-index-fr-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-haagsch-recherchebureau-index-pt-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/haagsch-recherchebureau/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-haagsch-recherchebureau-index-pt-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-hasbean-index-en-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/hasbean/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-hasbean-index-en-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-hasbean-index-es-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/hasbean/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-hasbean-index-es-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-hasbean-index-fr-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/hasbean/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-hasbean-index-fr-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-hasbean-index-pt-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/hasbean/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-hasbean-index-pt-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-hyperact-index-en-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/hyperact/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-hyperact-index-en-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-hyperact-index-es-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/hyperact/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-hyperact-index-es-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-hyperact-index-fr-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/hyperact/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-hyperact-index-fr-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-hyperact-index-pt-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/hyperact/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-hyperact-index-pt-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-liquona-index-en-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/liquona/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-liquona-index-en-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-liquona-index-es-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/liquona/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-liquona-index-es-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-liquona-index-fr-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/liquona/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-liquona-index-fr-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-liquona-index-pt-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/liquona/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-liquona-index-pt-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-melting-pot-creations-index-en-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/melting-pot-creations/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-melting-pot-creations-index-en-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-melting-pot-creations-index-es-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/melting-pot-creations/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-melting-pot-creations-index-es-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-melting-pot-creations-index-fr-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/melting-pot-creations/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-melting-pot-creations-index-fr-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-melting-pot-creations-index-pt-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/melting-pot-creations/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-melting-pot-creations-index-pt-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-oleary-index-en-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/oleary/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-oleary-index-en-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-oleary-index-es-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/oleary/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-oleary-index-es-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-oleary-index-fr-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/oleary/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-oleary-index-fr-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-oleary-index-pt-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/oleary/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-oleary-index-pt-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-pearl-scan-index-en-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/pearl-scan/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-pearl-scan-index-en-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-pearl-scan-index-es-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/pearl-scan/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-pearl-scan-index-es-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-pearl-scan-index-fr-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/pearl-scan/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-pearl-scan-index-fr-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-pearl-scan-index-pt-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/pearl-scan/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-pearl-scan-index-pt-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-rough-house-index-en-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/rough-house/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-rough-house-index-en-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-sookio-index-en-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/sookio/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-sookio-index-en-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-sookio-index-es-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/sookio/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-sookio-index-es-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-sookio-index-fr-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/sookio/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-sookio-index-fr-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-sookio-index-pt-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/sookio/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-sookio-index-pt-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-web-goddess-index-en-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/web-goddess/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-web-goddess-index-en-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-yfm-index-en-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/yfm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-yfm-index-en-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-yfm-index-es-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/yfm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-yfm-index-es-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-yfm-index-fr-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/yfm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-yfm-index-fr-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-yfm-index-pt-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/yfm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-yfm-index-pt-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-zigger-design-index-en-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/zigger-design/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-zigger-design-index-en-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-zigger-design-index-es-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/zigger-design/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-zigger-design-index-es-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-zigger-design-index-fr-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/zigger-design/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-zigger-design-index-fr-mdx" */),
  "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-zigger-design-index-pt-mdx": () => import("./../../../src/mdxPages/customer-story/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/customer-story/zigger-design/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-customer-story-layout-jsx-content-file-path-src-mdx-pages-customer-story-zigger-design-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-callbell-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/callbell/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-callbell-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-callbell-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/callbell/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-callbell-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-callbell-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/callbell/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-callbell-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-callbell-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/callbell/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-callbell-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-circleloop-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/circleloop-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-circleloop-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-circleloop-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/circleloop-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-circleloop-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-circleloop-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/circleloop-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-circleloop-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-circleloop-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/circleloop-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-circleloop-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-docusign-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/docusign/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-docusign-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-dripify-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/dripify/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-dripify-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-fireflies-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/fireflies/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-fireflies-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freeagent-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/freeagent-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freeagent-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freeagent-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/freeagent-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freeagent-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freeagent-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/freeagent-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freeagent-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freeagent-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/freeagent-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freeagent-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freshbooks-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/freshbooks-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freshbooks-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freshbooks-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/freshbooks-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freshbooks-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freshbooks-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/freshbooks-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freshbooks-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freshbooks-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/freshbooks-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freshbooks-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-gmail-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/gmail/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-gmail-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-gmail-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/gmail/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-gmail-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-gmail-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/gmail/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-gmail-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-gmail-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/gmail/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-gmail-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-calendar-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-calendar/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-calendar-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-calendar-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-calendar/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-calendar-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-calendar-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-calendar/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-calendar-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-calendar-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-calendar/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-calendar-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-contacts-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-contacts/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-contacts-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-contacts-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-contacts/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-contacts-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-contacts-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-contacts/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-contacts-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-contacts-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-contacts/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-contacts-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-workspace-single-sign-on-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-workspace-single-sign-on/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-workspace-single-sign-on-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-workspace-single-sign-on-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-workspace-single-sign-on/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-workspace-single-sign-on-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-workspace-single-sign-on-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-workspace-single-sign-on/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-workspace-single-sign-on-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-workspace-single-sign-on-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-workspace-single-sign-on/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-workspace-single-sign-on-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-kashflow-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/kashflow-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-kashflow-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-kashflow-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/kashflow-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-kashflow-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-kashflow-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/kashflow-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-kashflow-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-kashflow-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/kashflow-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-kashflow-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-looker-studio-connector-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/looker-studio-connector-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-looker-studio-connector-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-looker-studio-connector-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/looker-studio-connector-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-looker-studio-connector-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-looker-studio-connector-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/looker-studio-connector-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-looker-studio-connector-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-looker-studio-connector-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/looker-studio-connector-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-looker-studio-connector-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-mailchimp-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/mailchimp-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-mailchimp-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-mailchimp-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/mailchimp-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-mailchimp-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-mailchimp-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/mailchimp-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-mailchimp-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-mailchimp-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/mailchimp-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-mailchimp-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-calendar-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-calendar-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-calendar-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-calendar-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-calendar-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-calendar-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-calendar-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-calendar-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-calendar-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-calendar-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-calendar-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-calendar-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-outlook-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-outlook-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-outlook-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-outlook-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-outlook-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-outlook-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-outlook-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-outlook-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-outlook-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-outlook-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-outlook-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-outlook-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-single-sign-on-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-single-sign-on-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-single-sign-on-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-single-sign-on-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-single-sign-on-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-single-sign-on-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-single-sign-on-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-single-sign-on-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-single-sign-on-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-single-sign-on-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-single-sign-on-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-single-sign-on-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-teams-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-teams/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-teams-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-sage-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/sage-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-sage-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-sage-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/sage-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-sage-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-sage-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/sage-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-sage-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-sage-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/sage-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-sage-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-slack-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/slack/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-slack-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-squarespace-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/squarespace/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-squarespace-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-squarespace-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/squarespace/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-squarespace-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-squarespace-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/squarespace/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-squarespace-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-squarespace-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/squarespace/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-squarespace-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wati-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/wati/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wati-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wati-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/wati/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wati-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wati-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/wati/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wati-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wati-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/wati/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wati-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wufoo-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/wufoo-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wufoo-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wufoo-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/wufoo-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wufoo-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wufoo-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/wufoo-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wufoo-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wufoo-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/wufoo-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wufoo-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zapier-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/zapier-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zapier-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zapier-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/zapier-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zapier-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zapier-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/zapier-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zapier-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zapier-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/zapier-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zapier-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zendesk-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/zendesk-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zendesk-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zendesk-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/zendesk-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zendesk-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zendesk-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/zendesk-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zendesk-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zendesk-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/zendesk-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zendesk-crm-index-pt-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/dpa/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-restricted-transfers-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/dpa/restricted-transfers/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-restricted-transfers-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-schedule-1-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/dpa/schedule-1/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-schedule-1-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-schedule-2-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/dpa/schedule-2/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-schedule-2-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-schedule-3-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/dpa/schedule-3/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-schedule-3-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-sub-processors-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/dpa/sub-processors/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-sub-processors-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-eu-rep-addresses-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/eu-rep-addresses/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-eu-rep-addresses-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-gdpr-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/gdpr/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-gdpr-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-privacy-cookie-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/privacy/cookie/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-privacy-cookie-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-privacy-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/privacy/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-privacy-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-product-data-policy-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/product-data-policy/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-product-data-policy-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-referral-program-terms-and-conditions-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/referral-program-terms-and-conditions/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-referral-program-terms-and-conditions-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-security-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/security/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-security-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-terms-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/terms/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-terms-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-third-parties-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/third-parties/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-third-parties-index-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-deletion-index-en-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/deletion/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-deletion-index-en-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-deletion-index-es-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/deletion/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-deletion-index-es-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-deletion-index-fr-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/deletion/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-deletion-index-fr-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-deletion-index-pt-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/deletion/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-deletion-index-pt-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-free-crm-index-en-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/free-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-free-crm-index-en-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-free-crm-index-es-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/free-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-free-crm-index-es-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-free-crm-index-fr-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/free-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-free-crm-index-fr-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-free-crm-index-pt-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/free-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-free-crm-index-pt-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-what-is-a-crm-index-en-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/what-is-a-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-what-is-a-crm-index-en-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-what-is-a-crm-index-es-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/what-is-a-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-what-is-a-crm-index-es-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-what-is-a-crm-index-fr-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/what-is-a-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-what-is-a-crm-index-fr-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-what-is-a-crm-index-pt-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/what-is-a-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-what-is-a-crm-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-custom-fields-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-custom-fields/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-custom-fields-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-custom-fields-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-custom-fields/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-custom-fields-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-custom-fields-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-custom-fields/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-custom-fields-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-custom-fields-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-custom-fields/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-custom-fields-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-professional-plan-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-new-users-professional-plan/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-professional-plan-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-professional-plan-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-new-users-professional-plan/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-professional-plan-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-professional-plan-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-new-users-professional-plan/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-professional-plan-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-professional-plan-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-new-users-professional-plan/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-professional-plan-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-teams-plan-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-new-users-teams-plan/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-teams-plan-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-teams-plan-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-new-users-teams-plan/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-teams-plan-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-teams-plan-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-new-users-teams-plan/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-teams-plan-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-teams-plan-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-new-users-teams-plan/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-teams-plan-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-your-contacts-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-your-contacts/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-your-contacts-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-your-contacts-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-your-contacts/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-your-contacts-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-your-contacts-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-your-contacts/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-your-contacts-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-your-contacts-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-your-contacts/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-your-contacts-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-assign-records-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/assign-records/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-assign-records-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-assign-records-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/assign-records/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-assign-records-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-assign-records-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/assign-records/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-assign-records-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-assign-records-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/assign-records/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-assign-records-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-email-sending-email-templates-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/email-sending-email-templates/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-email-sending-email-templates-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-gmail-add-on-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/gmail-add-on/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-gmail-add-on-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-gmail-add-on-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/gmail-add-on/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-gmail-add-on-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-gmail-add-on-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/gmail-add-on/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-gmail-add-on-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-gmail-add-on-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/gmail-add-on/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-gmail-add-on-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-how-to-use-workflow-automations-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/how-to-use-workflow-automations/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-how-to-use-workflow-automations-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-how-to-use-workflow-automations-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/how-to-use-workflow-automations/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-how-to-use-workflow-automations-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-how-to-use-workflow-automations-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/how-to-use-workflow-automations/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-how-to-use-workflow-automations-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-how-to-use-workflow-automations-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/how-to-use-workflow-automations/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-how-to-use-workflow-automations-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-looker-studio-setup-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/looker-studio-setup/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-looker-studio-setup-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-looker-studio-setup-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/looker-studio-setup/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-looker-studio-setup-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-looker-studio-setup-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/looker-studio-setup/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-looker-studio-setup-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-looker-studio-setup-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/looker-studio-setup/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-looker-studio-setup-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-projects-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/manage-your-projects/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-projects-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-projects-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/manage-your-projects/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-projects-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-projects-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/manage-your-projects/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-projects-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-projects-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/manage-your-projects/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-projects-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-sales-pipeline-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/manage-your-sales-pipeline/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-sales-pipeline-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-sales-pipeline-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/manage-your-sales-pipeline/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-sales-pipeline-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-sales-pipeline-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/manage-your-sales-pipeline/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-sales-pipeline-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-sales-pipeline-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/manage-your-sales-pipeline/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-sales-pipeline-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-microsoft-outlook-add-in-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/microsoft-outlook-add-in/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-microsoft-outlook-add-in-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-microsoft-outlook-add-in-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/microsoft-outlook-add-in/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-microsoft-outlook-add-in-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-microsoft-outlook-add-in-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/microsoft-outlook-add-in/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-microsoft-outlook-add-in-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-microsoft-outlook-add-in-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/microsoft-outlook-add-in/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-microsoft-outlook-add-in-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-multiple-sales-pipelines-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/multiple-sales-pipelines/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-multiple-sales-pipelines-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-multiple-sales-pipelines-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/multiple-sales-pipelines/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-multiple-sales-pipelines-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-multiple-sales-pipelines-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/multiple-sales-pipelines/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-multiple-sales-pipelines-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-multiple-sales-pipelines-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/multiple-sales-pipelines/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-multiple-sales-pipelines-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-organizing-records-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/organizing-records/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-organizing-records-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-organizing-records-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/organizing-records/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-organizing-records-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-organizing-records-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/organizing-records/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-organizing-records-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-organizing-records-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/organizing-records/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-organizing-records-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-outlook-calendar-integration-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/outlook-calendar-integration/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-outlook-calendar-integration-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-outlook-calendar-integration-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/outlook-calendar-integration/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-outlook-calendar-integration-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-outlook-calendar-integration-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/outlook-calendar-integration/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-outlook-calendar-integration-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-outlook-calendar-integration-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/outlook-calendar-integration/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-outlook-calendar-integration-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-report-breakdowns-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/report-breakdowns/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-report-breakdowns-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-report-breakdowns-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/report-breakdowns/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-report-breakdowns-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-report-breakdowns-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/report-breakdowns/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-report-breakdowns-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-report-breakdowns-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/report-breakdowns/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-report-breakdowns-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-reporting-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/reporting/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-reporting-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-reporting-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/reporting/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-reporting-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-reporting-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/reporting/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-reporting-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-reporting-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/reporting/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-reporting-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-roles-and-teams-creation-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/roles-and-teams-creation/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-roles-and-teams-creation-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-roles-and-teams-creation-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/roles-and-teams-creation/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-roles-and-teams-creation-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-roles-and-teams-creation-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/roles-and-teams-creation/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-roles-and-teams-creation-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-roles-and-teams-creation-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/roles-and-teams-creation/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-roles-and-teams-creation-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-store-your-emails-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/store-your-emails/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-store-your-emails-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-store-your-emails-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/store-your-emails/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-store-your-emails-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-store-your-emails-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/store-your-emails/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-store-your-emails-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-store-your-emails-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/store-your-emails/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-store-your-emails-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-webinar-managing-your-data-in-capsule-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/webinar-managing-your-data-in-capsule/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-webinar-managing-your-data-in-capsule-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-xero-integration-setup-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/xero-integration-setup/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-xero-integration-setup-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-xero-integration-setup-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/xero-integration-setup/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-xero-integration-setup-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-xero-integration-setup-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/xero-integration-setup/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-xero-integration-setup-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-xero-integration-setup-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/xero-integration-setup/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-xero-integration-setup-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-account-responsibilities-and-super-administrator-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/account-responsibilities-and-super-administrator/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-account-responsibilities-and-super-administrator-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-account-responsibilities-and-super-administrator-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/account-responsibilities-and-super-administrator/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-account-responsibilities-and-super-administrator-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-account-responsibilities-and-super-administrator-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/account-responsibilities-and-super-administrator/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-account-responsibilities-and-super-administrator-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-account-responsibilities-and-super-administrator-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/account-responsibilities-and-super-administrator/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-account-responsibilities-and-super-administrator-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-billing-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/billing/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-billing-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-billing-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/billing/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-billing-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-billing-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/billing/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-billing-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-billing-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/billing/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-billing-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-exporting-and-canceling-your-account-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/exporting-and-canceling-your-account/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-exporting-and-canceling-your-account-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-exporting-and-canceling-your-account-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/exporting-and-canceling-your-account/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-exporting-and-canceling-your-account-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-exporting-and-canceling-your-account-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/exporting-and-canceling-your-account/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-exporting-and-canceling-your-account-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-exporting-and-canceling-your-account-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/exporting-and-canceling-your-account/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-exporting-and-canceling-your-account-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-managing-vat-information-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/managing-vat-information/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-managing-vat-information-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-managing-vat-information-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/managing-vat-information/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-managing-vat-information-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-managing-vat-information-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/managing-vat-information/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-managing-vat-information-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-managing-vat-information-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/managing-vat-information/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-managing-vat-information-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-security-and-data-protection-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/security-and-data-protection/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-security-and-data-protection-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-security-and-data-protection-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/security-and-data-protection/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-security-and-data-protection-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-security-and-data-protection-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/security-and-data-protection/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-security-and-data-protection-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-security-and-data-protection-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/account-and-security/security-and-data-protection/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-account-and-security-security-and-data-protection-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-how-to-export-reports-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/business-insights/how-to-export-reports/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-how-to-export-reports-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-how-to-export-reports-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/business-insights/how-to-export-reports/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-how-to-export-reports-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-how-to-export-reports-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/business-insights/how-to-export-reports/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-how-to-export-reports-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-how-to-export-reports-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/business-insights/how-to-export-reports/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-how-to-export-reports-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-how-to-track-your-completed-work-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/business-insights/how-to-track-your-completed-work/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-how-to-track-your-completed-work-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-how-to-track-your-completed-work-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/business-insights/how-to-track-your-completed-work/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-how-to-track-your-completed-work-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-how-to-track-your-completed-work-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/business-insights/how-to-track-your-completed-work/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-how-to-track-your-completed-work-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-how-to-track-your-completed-work-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/business-insights/how-to-track-your-completed-work/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-how-to-track-your-completed-work-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-increase-your-sales-and-productivity-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/business-insights/increase-your-sales-and-productivity/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-increase-your-sales-and-productivity-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-increase-your-sales-and-productivity-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/business-insights/increase-your-sales-and-productivity/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-increase-your-sales-and-productivity-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-increase-your-sales-and-productivity-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/business-insights/increase-your-sales-and-productivity/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-increase-your-sales-and-productivity-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-increase-your-sales-and-productivity-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/business-insights/increase-your-sales-and-productivity/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-increase-your-sales-and-productivity-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-reporting-on-your-sales-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/business-insights/reporting-on-your-sales/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-reporting-on-your-sales-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-reporting-on-your-sales-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/business-insights/reporting-on-your-sales/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-reporting-on-your-sales-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-reporting-on-your-sales-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/business-insights/reporting-on-your-sales/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-reporting-on-your-sales-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-reporting-on-your-sales-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/business-insights/reporting-on-your-sales/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-business-insights-reporting-on-your-sales-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-getting-started-with-the-calendar-and-tasks-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/getting-started-with-the-calendar-and-tasks/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-getting-started-with-the-calendar-and-tasks-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-getting-started-with-the-calendar-and-tasks-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/getting-started-with-the-calendar-and-tasks/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-getting-started-with-the-calendar-and-tasks-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-getting-started-with-the-calendar-and-tasks-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/getting-started-with-the-calendar-and-tasks/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-getting-started-with-the-calendar-and-tasks-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-getting-started-with-the-calendar-and-tasks-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/getting-started-with-the-calendar-and-tasks/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-getting-started-with-the-calendar-and-tasks-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-managing-your-tasks-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/managing-your-tasks/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-managing-your-tasks-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-managing-your-tasks-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/managing-your-tasks/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-managing-your-tasks-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-managing-your-tasks-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/managing-your-tasks/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-managing-your-tasks-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-managing-your-tasks-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/managing-your-tasks/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-managing-your-tasks-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-managing-your-teams-tasks-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/managing-your-teams-tasks/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-managing-your-teams-tasks-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-managing-your-teams-tasks-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/managing-your-teams-tasks/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-managing-your-teams-tasks-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-managing-your-teams-tasks-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/managing-your-teams-tasks/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-managing-your-teams-tasks-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-managing-your-teams-tasks-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/managing-your-teams-tasks/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-managing-your-teams-tasks-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-reporting-on-tasks-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/reporting-on-tasks/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-reporting-on-tasks-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-reporting-on-tasks-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/reporting-on-tasks/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-reporting-on-tasks-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-reporting-on-tasks-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/reporting-on-tasks/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-reporting-on-tasks-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-reporting-on-tasks-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/reporting-on-tasks/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-reporting-on-tasks-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-using-tasks-for-sales-and-projects-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/using-tasks-for-sales-and-projects/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-using-tasks-for-sales-and-projects-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-using-tasks-for-sales-and-projects-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/using-tasks-for-sales-and-projects/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-using-tasks-for-sales-and-projects-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-using-tasks-for-sales-and-projects-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/using-tasks-for-sales-and-projects/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-using-tasks-for-sales-and-projects-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-using-tasks-for-sales-and-projects-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/calendar-and-tasks/using-tasks-for-sales-and-projects/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-calendar-and-tasks-using-tasks-for-sales-and-projects-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-exporting-and-deleting-contacts-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/exporting-and-deleting-contacts/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-exporting-and-deleting-contacts-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-exporting-and-deleting-contacts-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/exporting-and-deleting-contacts/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-exporting-and-deleting-contacts-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-exporting-and-deleting-contacts-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/exporting-and-deleting-contacts/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-exporting-and-deleting-contacts-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-exporting-and-deleting-contacts-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/exporting-and-deleting-contacts/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-exporting-and-deleting-contacts-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-frequently-created-reports-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/frequently-created-reports/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-frequently-created-reports-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-frequently-created-reports-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/frequently-created-reports/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-frequently-created-reports-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-frequently-created-reports-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/frequently-created-reports/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-frequently-created-reports-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-frequently-created-reports-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/frequently-created-reports/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-frequently-created-reports-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-how-to-add-contacts-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/how-to-add-contacts/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-how-to-add-contacts-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-how-to-add-contacts-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/how-to-add-contacts/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-how-to-add-contacts-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-how-to-add-contacts-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/how-to-add-contacts/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-how-to-add-contacts-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-how-to-add-contacts-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/how-to-add-contacts/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-how-to-add-contacts-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-how-to-enrich-and-customize-your-contacts-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/how-to-enrich-and-customize-your-contacts/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-how-to-enrich-and-customize-your-contacts-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-how-to-enrich-and-customize-your-contacts-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/how-to-enrich-and-customize-your-contacts/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-how-to-enrich-and-customize-your-contacts-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-how-to-enrich-and-customize-your-contacts-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/how-to-enrich-and-customize-your-contacts/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-how-to-enrich-and-customize-your-contacts-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-how-to-enrich-and-customize-your-contacts-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/how-to-enrich-and-customize-your-contacts/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-how-to-enrich-and-customize-your-contacts-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-reporting-on-contacts-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/reporting-on-contacts/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-reporting-on-contacts-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-reporting-on-contacts-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/reporting-on-contacts/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-reporting-on-contacts-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-reporting-on-contacts-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/reporting-on-contacts/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-reporting-on-contacts-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-reporting-on-contacts-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/reporting-on-contacts/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-reporting-on-contacts-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-updating-or-editing-a-contact-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/updating-or-editing-a-contact/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-updating-or-editing-a-contact-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-updating-or-editing-a-contact-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/updating-or-editing-a-contact/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-updating-or-editing-a-contact-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-updating-or-editing-a-contact-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/updating-or-editing-a-contact/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-updating-or-editing-a-contact-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-updating-or-editing-a-contact-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/updating-or-editing-a-contact/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-updating-or-editing-a-contact-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-what-are-contacts-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/what-are-contacts/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-what-are-contacts-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-what-are-contacts-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/what-are-contacts/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-what-are-contacts-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-what-are-contacts-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/what-are-contacts/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-what-are-contacts-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-what-are-contacts-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/contact-management/what-are-contacts/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-contact-management-what-are-contacts-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-getting-started-with-importing-and-migration-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/importing-and-migrating-data/getting-started-with-importing-and-migration/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-getting-started-with-importing-and-migration-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-getting-started-with-importing-and-migration-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/importing-and-migrating-data/getting-started-with-importing-and-migration/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-getting-started-with-importing-and-migration-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-getting-started-with-importing-and-migration-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/importing-and-migrating-data/getting-started-with-importing-and-migration/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-getting-started-with-importing-and-migration-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-getting-started-with-importing-and-migration-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/importing-and-migrating-data/getting-started-with-importing-and-migration/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-getting-started-with-importing-and-migration-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-importing-contacts-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/importing-and-migrating-data/importing-contacts/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-importing-contacts-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-importing-contacts-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/importing-and-migrating-data/importing-contacts/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-importing-contacts-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-importing-contacts-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/importing-and-migrating-data/importing-contacts/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-importing-contacts-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-importing-contacts-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/importing-and-migrating-data/importing-contacts/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-importing-contacts-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-importing-opportunities-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/importing-and-migrating-data/importing-opportunities/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-importing-opportunities-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-importing-opportunities-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/importing-and-migrating-data/importing-opportunities/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-importing-opportunities-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-importing-opportunities-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/importing-and-migrating-data/importing-opportunities/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-importing-opportunities-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-importing-opportunities-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/importing-and-migrating-data/importing-opportunities/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-importing-opportunities-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-migrating-data-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/importing-and-migrating-data/migrating-data/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-migrating-data-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-migrating-data-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/importing-and-migrating-data/migrating-data/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-migrating-data-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-migrating-data-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/importing-and-migrating-data/migrating-data/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-migrating-data-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-migrating-data-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/importing-and-migrating-data/migrating-data/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-importing-and-migrating-data-migrating-data-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-callbell-integration-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/callbell-integration/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-callbell-integration-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-freeagent-integration-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/freeagent-integration/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-freeagent-integration-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-freeagent-integration-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/freeagent-integration/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-freeagent-integration-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-freeagent-integration-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/freeagent-integration/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-freeagent-integration-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-freeagent-integration-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/freeagent-integration/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-freeagent-integration-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-freshbooks-integration-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/freshbooks-integration/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-freshbooks-integration-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-freshbooks-integration-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/freshbooks-integration/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-freshbooks-integration-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-freshbooks-integration-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/freshbooks-integration/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-freshbooks-integration-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-freshbooks-integration-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/freshbooks-integration/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-freshbooks-integration-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-gmail-add-on-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/gmail-add-on/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-gmail-add-on-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-gmail-add-on-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/gmail-add-on/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-gmail-add-on-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-gmail-add-on-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/gmail-add-on/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-gmail-add-on-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-gmail-add-on-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/gmail-add-on/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-gmail-add-on-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-google-integrations-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/google-integrations/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-google-integrations-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-google-integrations-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/google-integrations/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-google-integrations-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-google-integrations-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/google-integrations/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-google-integrations-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-google-integrations-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/google-integrations/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-google-integrations-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-get-leads-from-a-website-form-into-capsule-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/how-to-get-leads-from-a-website-form-into-capsule/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-get-leads-from-a-website-form-into-capsule-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-get-leads-from-a-website-form-into-capsule-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/how-to-get-leads-from-a-website-form-into-capsule/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-get-leads-from-a-website-form-into-capsule-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-get-leads-from-a-website-form-into-capsule-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/how-to-get-leads-from-a-website-form-into-capsule/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-get-leads-from-a-website-form-into-capsule-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-get-leads-from-a-website-form-into-capsule-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/how-to-get-leads-from-a-website-form-into-capsule/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-get-leads-from-a-website-form-into-capsule-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-covve-with-capsule-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/how-to-integrate-covve-with-capsule/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-covve-with-capsule-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-docusign-with-capsule-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/how-to-integrate-docusign-with-capsule/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-docusign-with-capsule-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-dripify-with-capsule-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/how-to-integrate-dripify-with-capsule/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-dripify-with-capsule-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-fireflies-ai-with-capsule-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/how-to-integrate-fireflies-ai-with-capsule/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-fireflies-ai-with-capsule-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-fireflies-ai-with-capsule-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/how-to-integrate-fireflies-ai-with-capsule/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-fireflies-ai-with-capsule-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-microsoft-teams-and-capsule-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/how-to-integrate-Microsoft-Teams-and-Capsule/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-microsoft-teams-and-capsule-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-slack-with-capsule-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/how-to-integrate-Slack-with-Capsule/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-slack-with-capsule-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-with-capsule-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/how-to-integrate-with-capsule/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-with-capsule-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-with-capsule-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/how-to-integrate-with-capsule/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-with-capsule-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-with-capsule-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/how-to-integrate-with-capsule/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-with-capsule-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-with-capsule-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/how-to-integrate-with-capsule/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-integrate-with-capsule-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-link-a-squarespace-form-to-capsule-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/how-to-link-a-squarespace-form-to-capsule/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-link-a-squarespace-form-to-capsule-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-link-a-squarespace-form-to-capsule-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/how-to-link-a-squarespace-form-to-capsule/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-link-a-squarespace-form-to-capsule-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-link-a-squarespace-form-to-capsule-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/how-to-link-a-squarespace-form-to-capsule/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-link-a-squarespace-form-to-capsule-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-link-a-squarespace-form-to-capsule-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/how-to-link-a-squarespace-form-to-capsule/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-how-to-link-a-squarespace-form-to-capsule-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-kashflow-integration-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/kashflow-integration/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-kashflow-integration-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-kashflow-integration-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/kashflow-integration/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-kashflow-integration-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-kashflow-integration-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/kashflow-integration/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-kashflow-integration-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-kashflow-integration-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/kashflow-integration/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-kashflow-integration-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-looker-studio-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/looker-studio/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-looker-studio-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-looker-studio-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/looker-studio/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-looker-studio-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-looker-studio-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/looker-studio/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-looker-studio-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-looker-studio-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/looker-studio/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-looker-studio-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-mailchimp-integration-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/mailchimp-integration/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-mailchimp-integration-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-mailchimp-integration-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/mailchimp-integration/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-mailchimp-integration-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-mailchimp-integration-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/mailchimp-integration/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-mailchimp-integration-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-mailchimp-integration-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/mailchimp-integration/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-mailchimp-integration-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-marketing-with-transpond-by-capsule-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/marketing-with-transpond-by-capsule/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-marketing-with-transpond-by-capsule-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-marketing-with-transpond-by-capsule-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/marketing-with-transpond-by-capsule/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-marketing-with-transpond-by-capsule-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-marketing-with-transpond-by-capsule-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/marketing-with-transpond-by-capsule/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-marketing-with-transpond-by-capsule-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-marketing-with-transpond-by-capsule-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/marketing-with-transpond-by-capsule/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-marketing-with-transpond-by-capsule-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-microsoft-365-single-sign-on-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/microsoft-365-single-sign-on/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-microsoft-365-single-sign-on-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-microsoft-365-single-sign-on-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/microsoft-365-single-sign-on/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-microsoft-365-single-sign-on-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-microsoft-365-single-sign-on-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/microsoft-365-single-sign-on/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-microsoft-365-single-sign-on-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-microsoft-365-single-sign-on-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/microsoft-365-single-sign-on/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-microsoft-365-single-sign-on-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-outlook-add-in-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/outlook-add-in/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-outlook-add-in-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-outlook-add-in-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/outlook-add-in/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-outlook-add-in-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-outlook-add-in-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/outlook-add-in/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-outlook-add-in-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-outlook-add-in-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/outlook-add-in/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-outlook-add-in-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-outlook-calendar-integration-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/outlook-calendar-integration/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-outlook-calendar-integration-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-outlook-calendar-integration-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/outlook-calendar-integration/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-outlook-calendar-integration-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-outlook-calendar-integration-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/outlook-calendar-integration/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-outlook-calendar-integration-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-outlook-calendar-integration-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/outlook-calendar-integration/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-outlook-calendar-integration-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-quickbooks-integration-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/quickbooks-integration/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-quickbooks-integration-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-quickbooks-integration-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/quickbooks-integration/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-quickbooks-integration-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-quickbooks-integration-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/quickbooks-integration/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-quickbooks-integration-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-quickbooks-integration-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/quickbooks-integration/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-quickbooks-integration-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-quotient-integration-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/quotient-integration/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-quotient-integration-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-quotient-integration-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/quotient-integration/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-quotient-integration-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-quotient-integration-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/quotient-integration/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-quotient-integration-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-quotient-integration-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/quotient-integration/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-quotient-integration-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-sage-business-cloud-integration-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/sage-business-cloud-integration/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-sage-business-cloud-integration-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-sage-business-cloud-integration-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/sage-business-cloud-integration/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-sage-business-cloud-integration-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-sage-business-cloud-integration-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/sage-business-cloud-integration/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-sage-business-cloud-integration-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-sage-business-cloud-integration-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/sage-business-cloud-integration/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-sage-business-cloud-integration-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-social-network-integration-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/social-network-integration/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-social-network-integration-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-social-network-integration-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/social-network-integration/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-social-network-integration-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-social-network-integration-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/social-network-integration/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-social-network-integration-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-social-network-integration-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/social-network-integration/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-social-network-integration-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-wati-integration-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/wati-integration/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-wati-integration-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-wufoo-integration-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/wufoo-integration/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-wufoo-integration-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-wufoo-integration-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/wufoo-integration/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-wufoo-integration-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-wufoo-integration-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/wufoo-integration/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-wufoo-integration-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-wufoo-integration-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/wufoo-integration/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-wufoo-integration-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-xero-integration-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/xero-integration/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-xero-integration-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-xero-integration-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/xero-integration/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-xero-integration-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-xero-integration-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/xero-integration/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-xero-integration-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-xero-integration-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/xero-integration/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-xero-integration-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-zapier-integration-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/zapier-integration/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-zapier-integration-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-zapier-integration-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/zapier-integration/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-zapier-integration-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-zapier-integration-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/zapier-integration/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-zapier-integration-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-zapier-integration-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/zapier-integration/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-zapier-integration-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-zendesk-integration-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/zendesk-integration/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-zendesk-integration-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-zendesk-integration-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/zendesk-integration/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-zendesk-integration-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-zendesk-integration-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/zendesk-integration/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-zendesk-integration-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-zendesk-integration-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/integrations/zendesk-integration/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-integrations-zendesk-integration-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-mobile-apps-capsule-mobile-app-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/mobile-apps/capsule-mobile-app/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-mobile-apps-capsule-mobile-app-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-mobile-apps-capsule-mobile-app-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/mobile-apps/capsule-mobile-app/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-mobile-apps-capsule-mobile-app-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-mobile-apps-capsule-mobile-app-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/mobile-apps/capsule-mobile-app/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-mobile-apps-capsule-mobile-app-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-mobile-apps-capsule-mobile-app-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/mobile-apps/capsule-mobile-app/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-mobile-apps-capsule-mobile-app-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-ceo-announcement-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/ceo-announcement/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-ceo-announcement-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-ceo-announcement-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/ceo-announcement/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-ceo-announcement-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-ceo-announcement-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/ceo-announcement/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-ceo-announcement-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-ceo-announcement-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/ceo-announcement/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-ceo-announcement-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-eu-data-protection-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/eu-data-protection/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-eu-data-protection-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-eu-data-protection-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/eu-data-protection/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-eu-data-protection-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-eu-data-protection-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/eu-data-protection/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-eu-data-protection-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-eu-data-protection-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/eu-data-protection/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-eu-data-protection-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-gmail-scopes-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/gmail-scopes/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-gmail-scopes-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-gmail-scopes-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/gmail-scopes/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-gmail-scopes-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-gmail-scopes-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/gmail-scopes/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-gmail-scopes-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-gmail-scopes-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/gmail-scopes/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-gmail-scopes-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-google-workspace-scopes-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/google-workspace-scopes/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-google-workspace-scopes-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-google-workspace-scopes-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/google-workspace-scopes/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-google-workspace-scopes-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-google-workspace-scopes-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/google-workspace-scopes/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-google-workspace-scopes-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-google-workspace-scopes-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/google-workspace-scopes/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-google-workspace-scopes-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-important-fields-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/important-fields/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-important-fields-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-important-fields-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/important-fields/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-important-fields-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-important-fields-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/important-fields/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-important-fields-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-important-fields-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/important-fields/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-important-fields-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-troubleshooting-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/troubleshooting/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-troubleshooting-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-troubleshooting-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/troubleshooting/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-troubleshooting-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-troubleshooting-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/troubleshooting/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-troubleshooting-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-troubleshooting-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/troubleshooting/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-troubleshooting-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-zapier-migration-guide-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/zapier-migration-guide/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-zapier-migration-guide-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-zapier-migration-guide-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/zapier-migration-guide/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-zapier-migration-guide-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-zapier-migration-guide-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/zapier-migration-guide/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-zapier-migration-guide-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-zapier-migration-guide-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/one-off/zapier-migration-guide/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-one-off-zapier-migration-guide-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-post-template-index-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/post-template/index.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-post-template-index-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-adding-projects-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/adding-projects/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-adding-projects-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-adding-projects-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/adding-projects/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-adding-projects-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-adding-projects-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/adding-projects/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-adding-projects-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-adding-projects-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/adding-projects/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-adding-projects-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-customizing-projects-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/customizing-projects/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-customizing-projects-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-customizing-projects-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/customizing-projects/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-customizing-projects-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-customizing-projects-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/customizing-projects/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-customizing-projects-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-customizing-projects-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/customizing-projects/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-customizing-projects-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-deleting-projects-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/deleting-projects/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-deleting-projects-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-deleting-projects-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/deleting-projects/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-deleting-projects-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-deleting-projects-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/deleting-projects/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-deleting-projects-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-deleting-projects-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/deleting-projects/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-deleting-projects-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-manage-projects-using-boards-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/manage-projects-using-boards/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-manage-projects-using-boards-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-manage-projects-using-boards-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/manage-projects-using-boards/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-manage-projects-using-boards-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-manage-projects-using-boards-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/manage-projects-using-boards/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-manage-projects-using-boards-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-manage-projects-using-boards-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/manage-projects-using-boards/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-manage-projects-using-boards-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-understanding-projects-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/understanding-projects/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-understanding-projects-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-understanding-projects-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/understanding-projects/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-understanding-projects-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-understanding-projects-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/understanding-projects/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-understanding-projects-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-understanding-projects-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/understanding-projects/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-understanding-projects-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-updating-projects-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/updating-projects/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-updating-projects-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-updating-projects-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/updating-projects/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-updating-projects-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-updating-projects-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/updating-projects/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-updating-projects-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-updating-projects-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/updating-projects/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-updating-projects-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-using-projects-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/using-projects/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-using-projects-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-using-projects-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/using-projects/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-using-projects-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-using-projects-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/using-projects/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-using-projects-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-using-projects-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/projects-and-events/using-projects/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-projects-and-events-using-projects-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-adding-opportunities-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/adding-opportunities/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-adding-opportunities-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-adding-opportunities-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/adding-opportunities/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-adding-opportunities-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-adding-opportunities-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/adding-opportunities/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-adding-opportunities-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-adding-opportunities-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/adding-opportunities/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-adding-opportunities-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-an-overview-of-the-sales-pipeline-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/an-overview-of-the-sales-pipeline/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-an-overview-of-the-sales-pipeline-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-an-overview-of-the-sales-pipeline-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/an-overview-of-the-sales-pipeline/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-an-overview-of-the-sales-pipeline-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-an-overview-of-the-sales-pipeline-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/an-overview-of-the-sales-pipeline/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-an-overview-of-the-sales-pipeline-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-an-overview-of-the-sales-pipeline-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/an-overview-of-the-sales-pipeline/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-an-overview-of-the-sales-pipeline-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-customizing-your-opportunities-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/customizing-your-opportunities/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-customizing-your-opportunities-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-customizing-your-opportunities-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/customizing-your-opportunities/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-customizing-your-opportunities-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-customizing-your-opportunities-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/customizing-your-opportunities/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-customizing-your-opportunities-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-customizing-your-opportunities-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/customizing-your-opportunities/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-customizing-your-opportunities-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-managing-your-opportunities-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/managing-your-opportunities/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-managing-your-opportunities-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-managing-your-opportunities-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/managing-your-opportunities/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-managing-your-opportunities-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-managing-your-opportunities-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/managing-your-opportunities/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-managing-your-opportunities-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-managing-your-opportunities-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/managing-your-opportunities/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-managing-your-opportunities-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-multiple-sales-pipelines-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/multiple-sales-pipelines/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-multiple-sales-pipelines-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-multiple-sales-pipelines-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/multiple-sales-pipelines/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-multiple-sales-pipelines-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-multiple-sales-pipelines-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/multiple-sales-pipelines/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-multiple-sales-pipelines-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-multiple-sales-pipelines-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/multiple-sales-pipelines/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-multiple-sales-pipelines-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-sales-workflows-tracks-explained-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/sales-workflows-tracks-explained/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-sales-workflows-tracks-explained-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-sales-workflows-tracks-explained-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/sales-workflows-tracks-explained/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-sales-workflows-tracks-explained-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-sales-workflows-tracks-explained-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/sales-workflows-tracks-explained/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-sales-workflows-tracks-explained-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-sales-workflows-tracks-explained-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/sales-workflows-tracks-explained/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-sales-workflows-tracks-explained-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-stages-of-an-opportunity-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/stages-of-an-opportunity/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-stages-of-an-opportunity-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-stages-of-an-opportunity-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/stages-of-an-opportunity/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-stages-of-an-opportunity-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-stages-of-an-opportunity-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/stages-of-an-opportunity/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-stages-of-an-opportunity-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-stages-of-an-opportunity-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/stages-of-an-opportunity/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-stages-of-an-opportunity-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-understanding-opportunities-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/understanding-opportunities/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-understanding-opportunities-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-understanding-opportunities-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/understanding-opportunities/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-understanding-opportunities-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-understanding-opportunities-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/understanding-opportunities/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-understanding-opportunities-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-understanding-opportunities-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/sales/understanding-opportunities/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-sales-understanding-opportunities-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-business-enrichment-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/business-enrichment/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-business-enrichment-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-data-enrichment-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/data-enrichment/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-data-enrichment-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-data-enrichment-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/data-enrichment/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-data-enrichment-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-data-enrichment-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/data-enrichment/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-data-enrichment-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-data-enrichment-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/data-enrichment/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-data-enrichment-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-forward-as-attachment-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/forward-as-attachment/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-forward-as-attachment-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-forward-as-attachment-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/forward-as-attachment/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-forward-as-attachment-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-forward-as-attachment-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/forward-as-attachment/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-forward-as-attachment-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-forward-as-attachment-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/forward-as-attachment/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-forward-as-attachment-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-getting-started-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/getting-started/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-getting-started-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-getting-started-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/getting-started/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-getting-started-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-getting-started-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/getting-started/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-getting-started-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-getting-started-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/getting-started/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-getting-started-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-act-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-act/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-act-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-act-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-act/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-act-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-act-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-act/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-act-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-act-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-act/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-act-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-google-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-google/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-google-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-google-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-google/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-google-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-google-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-google/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-google-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-google-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-google/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-google-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-linkedin-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-linkedin/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-linkedin-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-linkedin-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-linkedin/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-linkedin-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-linkedin-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-linkedin/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-linkedin-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-linkedin-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-linkedin/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-linkedin-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-mac-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-mac/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-mac-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-mac-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-mac/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-mac-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-mac-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-mac/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-mac-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-mac-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-mac/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-mac-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-outlook-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-outlook/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-outlook-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-outlook-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-outlook/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-outlook-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-outlook-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-outlook/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-outlook-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-outlook-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-outlook/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-outlook-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-thunderbird-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-thunderbird/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-thunderbird-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-thunderbird-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-thunderbird/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-thunderbird-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-thunderbird-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-thunderbird/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-thunderbird-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-thunderbird-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/import-from-thunderbird/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-import-from-thunderbird-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-importing-and-migrating-data-old-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/importing-and-migrating-data-old/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-importing-and-migrating-data-old-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-importing-and-migrating-data-retired-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/importing-and-migrating-data-retired/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-importing-and-migrating-data-retired-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-importing-opportunities-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/importing-opportunities/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-importing-opportunities-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-microsoft-tnef-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/microsoft-tnef/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-microsoft-tnef-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-microsoft-tnef-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/microsoft-tnef/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-microsoft-tnef-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-microsoft-tnef-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/microsoft-tnef/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-microsoft-tnef-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-microsoft-tnef-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/microsoft-tnef/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-microsoft-tnef-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-navigating-capsule-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/navigating-capsule/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-navigating-capsule-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-navigating-capsule-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/navigating-capsule/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-navigating-capsule-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-navigating-capsule-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/navigating-capsule/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-navigating-capsule-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-navigating-capsule-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/navigating-capsule/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-navigating-capsule-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-sending-emails-and-email-templates-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/sending-emails-and-email-templates/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-sending-emails-and-email-templates-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-sending-emails-and-email-templates-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/sending-emails-and-email-templates/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-sending-emails-and-email-templates-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-sending-emails-and-email-templates-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/sending-emails-and-email-templates/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-sending-emails-and-email-templates-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-sending-emails-and-email-templates-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/sending-emails-and-email-templates/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-sending-emails-and-email-templates-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-storing-emails-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/storing-emails/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-storing-emails-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-storing-emails-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/storing-emails/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-storing-emails-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-storing-emails-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/storing-emails/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-storing-emails-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-storing-emails-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/storing-emails/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-storing-emails-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-system-requirements-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/system-requirements/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-system-requirements-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-system-requirements-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/system-requirements/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-system-requirements-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-system-requirements-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/system-requirements/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-system-requirements-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-system-requirements-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/system-requirements/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-system-requirements-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-tag-reeducation-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/tag-reeducation/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-tag-reeducation-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-teams-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/teams/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-teams-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-teams-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/teams/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-teams-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-teams-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/teams/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-teams-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-teams-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/teams/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-teams-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-user-guide-to-getting-started-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/user-guide-to-getting-started/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-user-guide-to-getting-started-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-user-guide-to-getting-started-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/user-guide-to-getting-started/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-user-guide-to-getting-started-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-user-guide-to-getting-started-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/user-guide-to-getting-started/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-user-guide-to-getting-started-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-user-guide-to-getting-started-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/user-guide-to-getting-started/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-user-guide-to-getting-started-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-user-management-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/user-management/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-user-management-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-user-management-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/user-management/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-user-management-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-user-management-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/user-management/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-user-management-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-user-management-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/setup-and-configuration/user-management/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-setup-and-configuration-user-management-index-pt-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-workflow-automation-getting-started-with-workflow-automation-index-en-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/workflow-automation/getting-started-with-workflow-automation/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-workflow-automation-getting-started-with-workflow-automation-index-en-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-workflow-automation-getting-started-with-workflow-automation-index-es-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/workflow-automation/getting-started-with-workflow-automation/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-workflow-automation-getting-started-with-workflow-automation-index-es-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-workflow-automation-getting-started-with-workflow-automation-index-fr-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/workflow-automation/getting-started-with-workflow-automation/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-workflow-automation-getting-started-with-workflow-automation-index-fr-mdx" */),
  "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-workflow-automation-getting-started-with-workflow-automation-index-pt-mdx": () => import("./../../../src/mdxPages/support/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/support/workflow-automation/getting-started-with-workflow-automation/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-support-layout-jsx-content-file-path-src-mdx-pages-support-workflow-automation-getting-started-with-workflow-automation-index-pt-mdx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-campaign-accountants-crm-index-jsx": () => import("./../../../src/pages/campaign/accountants-crm/index.jsx" /* webpackChunkName: "component---src-pages-campaign-accountants-crm-index-jsx" */),
  "component---src-pages-campaign-best-crm-for-business-index-jsx": () => import("./../../../src/pages/campaign/best-crm-for-business/index.jsx" /* webpackChunkName: "component---src-pages-campaign-best-crm-for-business-index-jsx" */),
  "component---src-pages-campaign-construction-contractors-crm-index-jsx": () => import("./../../../src/pages/campaign/construction-contractors-crm/index.jsx" /* webpackChunkName: "component---src-pages-campaign-construction-contractors-crm-index-jsx" */),
  "component---src-pages-campaign-crm-guide-index-jsx": () => import("./../../../src/pages/campaign/crm-guide/index.jsx" /* webpackChunkName: "component---src-pages-campaign-crm-guide-index-jsx" */),
  "component---src-pages-campaign-g-2-index-jsx": () => import("./../../../src/pages/campaign/g2/index.jsx" /* webpackChunkName: "component---src-pages-campaign-g-2-index-jsx" */),
  "component---src-pages-campaign-getting-started-index-jsx": () => import("./../../../src/pages/campaign/getting-started/index.jsx" /* webpackChunkName: "component---src-pages-campaign-getting-started-index-jsx" */),
  "component---src-pages-campaign-hotels-hospitality-crm-index-jsx": () => import("./../../../src/pages/campaign/hotels-hospitality-crm/index.jsx" /* webpackChunkName: "component---src-pages-campaign-hotels-hospitality-crm-index-jsx" */),
  "component---src-pages-campaign-it-tech-services-crm-index-jsx": () => import("./../../../src/pages/campaign/it-tech-services-crm/index.jsx" /* webpackChunkName: "component---src-pages-campaign-it-tech-services-crm-index-jsx" */),
  "component---src-pages-campaign-outlook-crm-index-jsx": () => import("./../../../src/pages/campaign/outlook-crm/index.jsx" /* webpackChunkName: "component---src-pages-campaign-outlook-crm-index-jsx" */),
  "component---src-pages-campaign-professional-services-crm-index-jsx": () => import("./../../../src/pages/campaign/professional-services-crm/index.jsx" /* webpackChunkName: "component---src-pages-campaign-professional-services-crm-index-jsx" */),
  "component---src-pages-campaign-quickbooks-index-jsx": () => import("./../../../src/pages/campaign/quickbooks/index.jsx" /* webpackChunkName: "component---src-pages-campaign-quickbooks-index-jsx" */),
  "component---src-pages-campaign-real-estate-crm-index-jsx": () => import("./../../../src/pages/campaign/real-estate-crm/index.jsx" /* webpackChunkName: "component---src-pages-campaign-real-estate-crm-index-jsx" */),
  "component---src-pages-campaign-sales-resources-index-jsx": () => import("./../../../src/pages/campaign/sales-resources/index.jsx" /* webpackChunkName: "component---src-pages-campaign-sales-resources-index-jsx" */),
  "component---src-pages-campaign-top-10-index-jsx": () => import("./../../../src/pages/campaign/top-10/index.jsx" /* webpackChunkName: "component---src-pages-campaign-top-10-index-jsx" */),
  "component---src-pages-campaign-travel-agencies-crm-index-jsx": () => import("./../../../src/pages/campaign/travel-agencies-crm/index.jsx" /* webpackChunkName: "component---src-pages-campaign-travel-agencies-crm-index-jsx" */),
  "component---src-pages-careers-index-jsx": () => import("./../../../src/pages/careers/index.jsx" /* webpackChunkName: "component---src-pages-careers-index-jsx" */),
  "component---src-pages-crm-comparisons-copper-jsx": () => import("./../../../src/pages/crm-comparisons/copper.jsx" /* webpackChunkName: "component---src-pages-crm-comparisons-copper-jsx" */),
  "component---src-pages-crm-comparisons-freshsales-jsx": () => import("./../../../src/pages/crm-comparisons/freshsales.jsx" /* webpackChunkName: "component---src-pages-crm-comparisons-freshsales-jsx" */),
  "component---src-pages-crm-comparisons-index-jsx": () => import("./../../../src/pages/crm-comparisons/index.jsx" /* webpackChunkName: "component---src-pages-crm-comparisons-index-jsx" */),
  "component---src-pages-crm-comparisons-insightly-jsx": () => import("./../../../src/pages/crm-comparisons/insightly.jsx" /* webpackChunkName: "component---src-pages-crm-comparisons-insightly-jsx" */),
  "component---src-pages-crm-comparisons-nimble-jsx": () => import("./../../../src/pages/crm-comparisons/nimble.jsx" /* webpackChunkName: "component---src-pages-crm-comparisons-nimble-jsx" */),
  "component---src-pages-crm-comparisons-pipedrive-jsx": () => import("./../../../src/pages/crm-comparisons/pipedrive.jsx" /* webpackChunkName: "component---src-pages-crm-comparisons-pipedrive-jsx" */),
  "component---src-pages-crm-comparisons-zoho-jsx": () => import("./../../../src/pages/crm-comparisons/zoho.jsx" /* webpackChunkName: "component---src-pages-crm-comparisons-zoho-jsx" */),
  "component---src-pages-crm-software-jsx": () => import("./../../../src/pages/crm-software.jsx" /* webpackChunkName: "component---src-pages-crm-software-jsx" */),
  "component---src-pages-features-ai-content-assistant-jsx": () => import("./../../../src/pages/features/ai-content-assistant.jsx" /* webpackChunkName: "component---src-pages-features-ai-content-assistant-jsx" */),
  "component---src-pages-features-contact-management-software-jsx": () => import("./../../../src/pages/features/contact-management-software.jsx" /* webpackChunkName: "component---src-pages-features-contact-management-software-jsx" */),
  "component---src-pages-features-customize-capsule-jsx": () => import("./../../../src/pages/features/customize-capsule.jsx" /* webpackChunkName: "component---src-pages-features-customize-capsule-jsx" */),
  "component---src-pages-features-email-marketing-jsx": () => import("./../../../src/pages/features/email-marketing.jsx" /* webpackChunkName: "component---src-pages-features-email-marketing-jsx" */),
  "component---src-pages-features-email-templates-jsx": () => import("./../../../src/pages/features/email-templates.jsx" /* webpackChunkName: "component---src-pages-features-email-templates-jsx" */),
  "component---src-pages-features-growth-jsx": () => import("./../../../src/pages/features/growth.jsx" /* webpackChunkName: "component---src-pages-features-growth-jsx" */),
  "component---src-pages-features-index-jsx": () => import("./../../../src/pages/features/index.jsx" /* webpackChunkName: "component---src-pages-features-index-jsx" */),
  "component---src-pages-features-mobile-app-jsx": () => import("./../../../src/pages/features/mobile-app.jsx" /* webpackChunkName: "component---src-pages-features-mobile-app-jsx" */),
  "component---src-pages-features-project-management-crm-jsx": () => import("./../../../src/pages/features/project-management-crm.jsx" /* webpackChunkName: "component---src-pages-features-project-management-crm-jsx" */),
  "component---src-pages-features-sales-analytics-jsx": () => import("./../../../src/pages/features/sales-analytics.jsx" /* webpackChunkName: "component---src-pages-features-sales-analytics-jsx" */),
  "component---src-pages-features-sales-pipeline-jsx": () => import("./../../../src/pages/features/sales-pipeline.jsx" /* webpackChunkName: "component---src-pages-features-sales-pipeline-jsx" */),
  "component---src-pages-features-sales-tracking-software-jsx": () => import("./../../../src/pages/features/sales-tracking-software.jsx" /* webpackChunkName: "component---src-pages-features-sales-tracking-software-jsx" */),
  "component---src-pages-features-security-and-permissions-jsx": () => import("./../../../src/pages/features/security-and-permissions.jsx" /* webpackChunkName: "component---src-pages-features-security-and-permissions-jsx" */),
  "component---src-pages-features-tasks-and-calendar-jsx": () => import("./../../../src/pages/features/tasks-and-calendar.jsx" /* webpackChunkName: "component---src-pages-features-tasks-and-calendar-jsx" */),
  "component---src-pages-features-workflow-automation-jsx": () => import("./../../../src/pages/features/workflow-automation.jsx" /* webpackChunkName: "component---src-pages-features-workflow-automation-jsx" */),
  "component---src-pages-google-workspace-crm-jsx": () => import("./../../../src/pages/google-workspace-crm.jsx" /* webpackChunkName: "component---src-pages-google-workspace-crm-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industries-crm-for-accountants-jsx": () => import("./../../../src/pages/industries/crm-for-accountants.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-accountants-jsx" */),
  "component---src-pages-industries-crm-for-architects-jsx": () => import("./../../../src/pages/industries/crm-for-architects.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-architects-jsx" */),
  "component---src-pages-industries-crm-for-auto-dealers-jsx": () => import("./../../../src/pages/industries/crm-for-auto-dealers.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-auto-dealers-jsx" */),
  "component---src-pages-industries-crm-for-construction-jsx": () => import("./../../../src/pages/industries/crm-for-construction.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-construction-jsx" */),
  "component---src-pages-industries-crm-for-consultants-jsx": () => import("./../../../src/pages/industries/crm-for-consultants.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-consultants-jsx" */),
  "component---src-pages-industries-crm-for-customer-service-jsx": () => import("./../../../src/pages/industries/crm-for-customer-service.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-customer-service-jsx" */),
  "component---src-pages-industries-crm-for-distributors-jsx": () => import("./../../../src/pages/industries/crm-for-distributors.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-distributors-jsx" */),
  "component---src-pages-industries-crm-for-field-services-jsx": () => import("./../../../src/pages/industries/crm-for-field-services.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-field-services-jsx" */),
  "component---src-pages-industries-crm-for-finance-jsx": () => import("./../../../src/pages/industries/crm-for-finance.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-finance-jsx" */),
  "component---src-pages-industries-crm-for-freelancers-jsx": () => import("./../../../src/pages/industries/crm-for-freelancers.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-freelancers-jsx" */),
  "component---src-pages-industries-crm-for-healthcare-jsx": () => import("./../../../src/pages/industries/crm-for-healthcare.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-healthcare-jsx" */),
  "component---src-pages-industries-crm-for-hotels-and-hospitality-jsx": () => import("./../../../src/pages/industries/crm-for-hotels-and-hospitality.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-hotels-and-hospitality-jsx" */),
  "component---src-pages-industries-crm-for-law-jsx": () => import("./../../../src/pages/industries/crm-for-law.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-law-jsx" */),
  "component---src-pages-industries-crm-for-manufacturers-jsx": () => import("./../../../src/pages/industries/crm-for-manufacturers.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-manufacturers-jsx" */),
  "component---src-pages-industries-crm-for-marketing-jsx": () => import("./../../../src/pages/industries/crm-for-marketing.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-marketing-jsx" */),
  "component---src-pages-industries-crm-for-medium-sized-businesses-jsx": () => import("./../../../src/pages/industries/crm-for-medium-sized-businesses.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-medium-sized-businesses-jsx" */),
  "component---src-pages-industries-crm-for-nonprofits-jsx": () => import("./../../../src/pages/industries/crm-for-nonprofits.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-nonprofits-jsx" */),
  "component---src-pages-industries-crm-for-office-365-jsx": () => import("./../../../src/pages/industries/crm-for-office-365.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-office-365-jsx" */),
  "component---src-pages-industries-crm-for-photographers-jsx": () => import("./../../../src/pages/industries/crm-for-photographers.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-photographers-jsx" */),
  "component---src-pages-industries-crm-for-private-equity-jsx": () => import("./../../../src/pages/industries/crm-for-private-equity.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-private-equity-jsx" */),
  "component---src-pages-industries-crm-for-professional-services-jsx": () => import("./../../../src/pages/industries/crm-for-professional-services.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-professional-services-jsx" */),
  "component---src-pages-industries-crm-for-property-management-jsx": () => import("./../../../src/pages/industries/crm-for-property-management.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-property-management-jsx" */),
  "component---src-pages-industries-crm-for-real-estate-jsx": () => import("./../../../src/pages/industries/crm-for-real-estate.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-real-estate-jsx" */),
  "component---src-pages-industries-crm-for-recruitment-jsx": () => import("./../../../src/pages/industries/crm-for-recruitment.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-recruitment-jsx" */),
  "component---src-pages-industries-crm-for-restaurants-jsx": () => import("./../../../src/pages/industries/crm-for-restaurants.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-restaurants-jsx" */),
  "component---src-pages-industries-crm-for-saas-jsx": () => import("./../../../src/pages/industries/crm-for-saas.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-saas-jsx" */),
  "component---src-pages-industries-crm-for-schools-jsx": () => import("./../../../src/pages/industries/crm-for-schools.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-schools-jsx" */),
  "component---src-pages-industries-crm-for-startups-jsx": () => import("./../../../src/pages/industries/crm-for-startups.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-startups-jsx" */),
  "component---src-pages-industries-crm-for-technology-services-jsx": () => import("./../../../src/pages/industries/crm-for-technology-services.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-technology-services-jsx" */),
  "component---src-pages-industries-crm-for-telemarketing-jsx": () => import("./../../../src/pages/industries/crm-for-telemarketing.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-telemarketing-jsx" */),
  "component---src-pages-industries-crm-for-travel-agencies-jsx": () => import("./../../../src/pages/industries/crm-for-travel-agencies.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-travel-agencies-jsx" */),
  "component---src-pages-industries-crm-for-wholesaleing-jsx": () => import("./../../../src/pages/industries/crm-for-wholesaleing.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-wholesaleing-jsx" */),
  "component---src-pages-industries-crm-small-business-jsx": () => import("./../../../src/pages/industries/crm-small-business.jsx" /* webpackChunkName: "component---src-pages-industries-crm-small-business-jsx" */),
  "component---src-pages-integrations-index-jsx": () => import("./../../../src/pages/integrations/index.jsx" /* webpackChunkName: "component---src-pages-integrations-index-jsx" */),
  "component---src-pages-integrations-quickbooks-crm-index-jsx": () => import("./../../../src/pages/integrations/quickbooks-crm/index.jsx" /* webpackChunkName: "component---src-pages-integrations-quickbooks-crm-index-jsx" */),
  "component---src-pages-integrations-transpond-index-jsx": () => import("./../../../src/pages/integrations/transpond/index.jsx" /* webpackChunkName: "component---src-pages-integrations-transpond-index-jsx" */),
  "component---src-pages-integrations-xero-crm-index-jsx": () => import("./../../../src/pages/integrations/xero-crm/index.jsx" /* webpackChunkName: "component---src-pages-integrations-xero-crm-index-jsx" */),
  "component---src-pages-invite-jsx": () => import("./../../../src/pages/invite.jsx" /* webpackChunkName: "component---src-pages-invite-jsx" */),
  "component---src-pages-media-index-jsx": () => import("./../../../src/pages/media/index.jsx" /* webpackChunkName: "component---src-pages-media-index-jsx" */),
  "component---src-pages-microsoft-365-integrations-crm-index-jsx": () => import("./../../../src/pages/microsoft-365-integrations-crm/index.jsx" /* webpackChunkName: "component---src-pages-microsoft-365-integrations-crm-index-jsx" */),
  "component---src-pages-new-campaign-top-10-index-jsx": () => import("./../../../src/pages/new-campaign/top-10/index.jsx" /* webpackChunkName: "component---src-pages-new-campaign-top-10-index-jsx" */),
  "component---src-pages-newsletter-thanks-jsx": () => import("./../../../src/pages/newsletter-thanks.jsx" /* webpackChunkName: "component---src-pages-newsletter-thanks-jsx" */),
  "component---src-pages-partner-program-affiliate-index-jsx": () => import("./../../../src/pages/partner-program/affiliate/index.jsx" /* webpackChunkName: "component---src-pages-partner-program-affiliate-index-jsx" */),
  "component---src-pages-partner-program-index-jsx": () => import("./../../../src/pages/partner-program/index.jsx" /* webpackChunkName: "component---src-pages-partner-program-index-jsx" */),
  "component---src-pages-partner-program-smb-index-jsx": () => import("./../../../src/pages/partner-program/smb/index.jsx" /* webpackChunkName: "component---src-pages-partner-program-smb-index-jsx" */),
  "component---src-pages-partner-program-solution-index-jsx": () => import("./../../../src/pages/partner-program/solution/index.jsx" /* webpackChunkName: "component---src-pages-partner-program-solution-index-jsx" */),
  "component---src-pages-partner-program-technology-index-jsx": () => import("./../../../src/pages/partner-program/technology/index.jsx" /* webpackChunkName: "component---src-pages-partner-program-technology-index-jsx" */),
  "component---src-pages-partners-1st-formations-jsx": () => import("./../../../src/pages/partners/1st-formations.jsx" /* webpackChunkName: "component---src-pages-partners-1st-formations-jsx" */),
  "component---src-pages-partners-capsule-jsx": () => import("./../../../src/pages/partners/capsule.jsx" /* webpackChunkName: "component---src-pages-partners-capsule-jsx" */),
  "component---src-pages-partners-donut-jsx": () => import("./../../../src/pages/partners/donut.jsx" /* webpackChunkName: "component---src-pages-partners-donut-jsx" */),
  "component---src-pages-partners-founderpass-jsx": () => import("./../../../src/pages/partners/founderpass.jsx" /* webpackChunkName: "component---src-pages-partners-founderpass-jsx" */),
  "component---src-pages-partners-index-jsx": () => import("./../../../src/pages/partners/index.jsx" /* webpackChunkName: "component---src-pages-partners-index-jsx" */),
  "component---src-pages-partners-scrutton-bland-jsx": () => import("./../../../src/pages/partners/scrutton-bland.jsx" /* webpackChunkName: "component---src-pages-partners-scrutton-bland-jsx" */),
  "component---src-pages-partners-vk-business-consulting-ltd-jsx": () => import("./../../../src/pages/partners/vk-business-consulting-ltd.jsx" /* webpackChunkName: "component---src-pages-partners-vk-business-consulting-ltd-jsx" */),
  "component---src-pages-quickbooks-disconnected-jsx": () => import("./../../../src/pages/quickbooks-disconnected.jsx" /* webpackChunkName: "component---src-pages-quickbooks-disconnected-jsx" */),
  "component---src-pages-resources-customer-stories-index-jsx": () => import("./../../../src/pages/resources/customer-stories/index.jsx" /* webpackChunkName: "component---src-pages-resources-customer-stories-index-jsx" */),
  "component---src-pages-resources-index-jsx": () => import("./../../../src/pages/resources/index.jsx" /* webpackChunkName: "component---src-pages-resources-index-jsx" */),
  "component---src-pages-signup-index-jsx": () => import("./../../../src/pages/signup/index.jsx" /* webpackChunkName: "component---src-pages-signup-index-jsx" */),
  "component---src-pages-support-account-and-security-index-jsx": () => import("./../../../src/pages/support/account-and-security/index.jsx" /* webpackChunkName: "component---src-pages-support-account-and-security-index-jsx" */),
  "component---src-pages-support-business-insights-index-jsx": () => import("./../../../src/pages/support/business-insights/index.jsx" /* webpackChunkName: "component---src-pages-support-business-insights-index-jsx" */),
  "component---src-pages-support-calendar-and-tasks-index-jsx": () => import("./../../../src/pages/support/calendar-and-tasks/index.jsx" /* webpackChunkName: "component---src-pages-support-calendar-and-tasks-index-jsx" */),
  "component---src-pages-support-contact-management-index-jsx": () => import("./../../../src/pages/support/contact-management/index.jsx" /* webpackChunkName: "component---src-pages-support-contact-management-index-jsx" */),
  "component---src-pages-support-contact-us-jsx": () => import("./../../../src/pages/support/contact-us.jsx" /* webpackChunkName: "component---src-pages-support-contact-us-jsx" */),
  "component---src-pages-support-importing-and-migrating-data-index-jsx": () => import("./../../../src/pages/support/importing-and-migrating-data/index.jsx" /* webpackChunkName: "component---src-pages-support-importing-and-migrating-data-index-jsx" */),
  "component---src-pages-support-index-jsx": () => import("./../../../src/pages/support/index.jsx" /* webpackChunkName: "component---src-pages-support-index-jsx" */),
  "component---src-pages-support-integrations-index-jsx": () => import("./../../../src/pages/support/integrations/index.jsx" /* webpackChunkName: "component---src-pages-support-integrations-index-jsx" */),
  "component---src-pages-support-mobile-apps-index-jsx": () => import("./../../../src/pages/support/mobile-apps/index.jsx" /* webpackChunkName: "component---src-pages-support-mobile-apps-index-jsx" */),
  "component---src-pages-support-projects-and-events-index-jsx": () => import("./../../../src/pages/support/projects-and-events/index.jsx" /* webpackChunkName: "component---src-pages-support-projects-and-events-index-jsx" */),
  "component---src-pages-support-sales-index-jsx": () => import("./../../../src/pages/support/sales/index.jsx" /* webpackChunkName: "component---src-pages-support-sales-index-jsx" */),
  "component---src-pages-support-search-index-jsx": () => import("./../../../src/pages/support/search/index.jsx" /* webpackChunkName: "component---src-pages-support-search-index-jsx" */),
  "component---src-pages-support-setup-and-configuration-index-jsx": () => import("./../../../src/pages/support/setup-and-configuration/index.jsx" /* webpackChunkName: "component---src-pages-support-setup-and-configuration-index-jsx" */),
  "component---src-pages-support-workflow-automation-index-jsx": () => import("./../../../src/pages/support/workflow-automation/index.jsx" /* webpackChunkName: "component---src-pages-support-workflow-automation-index-jsx" */),
  "component---src-pages-tiers-jsx": () => import("./../../../src/pages/tiers.jsx" /* webpackChunkName: "component---src-pages-tiers-jsx" */),
  "component---src-pages-ultimate-index-jsx": () => import("./../../../src/pages/ultimate/index.jsx" /* webpackChunkName: "component---src-pages-ultimate-index-jsx" */),
  "component---src-templates-blog-category-template-jsx": () => import("./../../../src/templates/blog-category-template.jsx" /* webpackChunkName: "component---src-templates-blog-category-template-jsx" */)
}

